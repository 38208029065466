import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CardPaymentActionBtnsValEnum, NameFromServerKeyEnum, PaymentMethodsLblEnum, PaymentMethodsValEnum } from '@app/constants-enums/enums';
import { LoginService } from '../login.service';
import { IMG_ADD_FILE_SVG, IMG_ICON_COLUMN_CHOOSER, IMG_ICON_REFRESH, IMG_ICON_XLSXFILEPOPUPIX, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import { RestApiService } from '../rest-service/rest-api.service';
import { SalesDataSharingService } from '@app/services/sales-service/sales-data-sharing.service';
import { CrmsService } from '@app/services/crms-service/crms-service.service';
import { from, Observable } from 'rxjs';
import saveAs from 'file-saver';
import { CurrencyModel, CurrencyOptions } from '@app/models/common.model';

@Injectable({
	providedIn: 'root'
})
export class AppCommonSrvc {
	public pathCss = '/assets/css/';
	public pathImg = '/assets/images/';
	public pathSvg = '/assets/images/svgs/';
	public pathJs = '/assets/js/';
	public pathJson = '/assets/JSON/';
	public cdnPath: string = environment.URL_CDN_PROTOCOL_SECURE + environment.URL_CDN_PREFIX + environment.URL_CDN_DOMAIN + environment.URL_CDN_POSTFIX;
	public cdnCssPath: string = this.cdnPath + this.pathCss;
	public cdnImgPath: string = this.cdnPath + this.pathImg;
	public cdnSvgPath: string = this.cdnPath + this.pathSvg;
	public cdnJsPath: string = this.cdnPath + this.pathJs;
	public cdnJsonPath: string = this.cdnPath + this.pathJson;
	private selectedReportTab: any;
	private selectedApplicationConfigsTab: any;
	private selectedApplicationSystemConfigsTab: any;
	private backClick: boolean;
	private isAutoLogOutPopUpOpen: boolean = false;
	private userSettings: any[] = [];
	private _AppStateKeyVal: any = {};
	private popupHeightWidth: any;
	private notificationTab: any;
	private notificationSubTab: any;
	private notificationCall: boolean = false;
	private listOfFeatureCustomFields: any[];
	public passwordLength: number = 12;
	public fetchListOfCurrencySbsn

	constructor(private _RestApiService: RestApiService,
		private _LoginService: LoginService,
		private _CrmsService: CrmsService,
		private _SalesDataSharingService: SalesDataSharingService) {

	}

	setSelectedReportTab(val: any) {
		this.selectedReportTab = val;
	}

	getSelectedReportTab() {
		return this.selectedReportTab || undefined;
	}

	setSelectedApplicationConfigsTab(val: any) {
		this.selectedApplicationConfigsTab = val;
	}

	setSelectedApplicationSystemConfigsTab(val: any) {
		this.selectedApplicationSystemConfigsTab = val;
	}

	getSelectedApplicationConfigsTab() {
		return this.selectedApplicationConfigsTab || undefined;
	}

	getSelectedApplicationSystemConfigsTab() {
		return this.selectedApplicationSystemConfigsTab || undefined;
	}

	setBackClicked(val: any) {
		this.backClick = val;
	}

	getBackClicked() {
		return this.backClick || undefined;
	}

	setAutoLogOutPopUpStatus(val: any) {
		this.isAutoLogOutPopUpOpen = val;
	}

	getAutoLogOutPopUpStatus() {
		return this.isAutoLogOutPopUpOpen;
	}

	setNotificationTab(val: any) {
		this.notificationTab = val;
	}

	getNotificationTab() {
		return this.notificationTab || undefined;
	}

	setNotificationSubTab(val: any) {
		this.notificationSubTab = val;
	}

	getNotificationSubTab() {
		return this.notificationSubTab || undefined;
	}

	setNotificationCall(val: any) {
		this.notificationCall = val;
	}

	getNotificationCall() {
		return this.notificationCall;
	}
	doGetListofCommonStatus() {
		return [{
			"guid": "b67405da8a6de034625a2b4ace3c2ce7",
			"value": 'active',
			"label": "ACTIVE",
			"text": "ACTIVE",
			"bgcolor": "#03b86c"
		},
		{
			"guid": "9ea137e004825073e732a5dac6a37702",
			"value": 'inactive',
			"label": "INACTIVE",
			"text": "INACTIVE",
			"bgcolor": "#f2b80c"
		}];
	}
	doGetBooleanStatusList() {
		return [{
			"guid": "b67405da8a6de034625a2b4ace3c2ce7",
			"value": true,
			"label": "Active",
			"bgcolor": "#03b86c"
		},
		{
			"guid": "9ea137e004825073e732a5dac6a37702",
			"value": false,
			"label": "Inactive",
			"bgcolor": "#f2b80c"
		}];
	}
	doGetCarPaymentActionList() {
		return [{
			"btnCode": CardPaymentActionBtnsValEnum.SETASDEFAULT,
			"btnImage": "icon-credit-card",
			"btnName": "Set as default",
			"disabled": false,
			"icon": " icon-credit-card",
			"visible": false,
		},
		{
			"btnCode": CardPaymentActionBtnsValEnum.DELETE,
			"btnImage": "icon-delete",
			"btnName": "Delete",
			"disabled": false,
			"icon": " icon-delete",
			"visible": true
		}];
	}
	doGetListofApprovalStatus() {
		return [{
			"guid": "b67405da8a6de034625a2b4ace3c2ce7",
			"value": 'approved' || 'approve',
			"label": "APPROVE",
			"text": "APPROVE",
			"finaltext": "APPROVED",
			"bgcolor": "#03b86c",
			"classname": "greenBg",
		},
		{
			"guid": "1ea137e004825073e732a5dac6a15487",
			"value": 'inprocess' || '',
			"label": "IN PROCESS",
			"text": "IN PROCESS",
			"finaltext": "IN PROCESS",
			"bgcolor": "#f2b80c",
			"classname": "orangeBg",
		},
		{
			"guid": "3ea139e0000025073e732a5dac6a9552134",
			"value": 'under_review',
			"label": "MARK CLAIM TO REDO",
			"text": " MARK CLAIM TO REDO",
			"finaltext": "UNDER REVIEW",
			"bgcolor": "#f2b80c",
			"classname": "orangeBg",
		}
			// {
			// 	"guid": "9ea137e004825073e732a5dac6a37702",
			// 	"value": 'decline',
			// 	"label": "DECLINE",
			// 	"text": "DECLINE",
			// 	"finaltext": "DECLINED",
			// 	"bgcolor": "#f2b80c",
			// 	"classname": "redBg",
			// }
		];
	}
	doGetListofApprovalStatusForHeaderFilter() {
		return [{
			"guid": "b67405da8a6de034625a2b4ace3c2ce7",
			"value": 'approved',
			"label": "APPROVED",
			"text": "APPROVED",
			"finaltext": "APPROVED",
			"bgcolor": "#03b86c",
			"classname": "greenBg",
		},
		{
			"guid": "1ea137e004825073e732a5dac6a15487",
			"value": 'inprocess',
			"label": "IN PROCESS",
			"text": "IN PROCESS",
			"finaltext": "IN PROCESS",
			"bgcolor": "#f2b80c",
			"classname": "orangeBg",
		},
		{
			"guid": "3ea139e0000025073e732a5dac6a9552134",
			"value": 'under_review',
			"label": "UNDER REVIEW",
			"text": "UNDER REVIEW",
			"finaltext": "UNDER REVIEW",
			"bgcolor": "#f2b80c",
			"classname": "orangeBg",
		},
		{
			"guid": "9ea111e004825073e732a5dac6a9874",
			"value": 'cancelled',
			"label": "CANCELLED",
			"text": "CANCELLED",
			"finaltext": "CANCELLED",
			"bgcolor": "#f2b80c",
			"classname": "red",
			"classnameBg": "redBg",
		}
		];
	}
	// Method for ToFixed Decimal number without rounding.
	toFixedWithoutRounding(valueNum: number, decimalNum: number) {
		const re = new RegExp('^-?\\d+(?:\.\\d{0,' + (decimalNum || -1) + '})?');
		return +Number(valueNum).toFixed(decimalNum).toString().match(re)[0];
	}

	public getPaymentMethods() {
		return [
			{
				code: PaymentMethodsValEnum.CARD_NOX,
				label: PaymentMethodsLblEnum.CARD_NOX
			},
			{
				code: PaymentMethodsValEnum.USA_EPAY,
				label: PaymentMethodsLblEnum.USA_EPAY
			},
			{
				code: PaymentMethodsValEnum.AUTHORIZE_DOTNET,
				label: PaymentMethodsLblEnum.AUTHORIZE_DOTNET
			}
		];
	}
	public setUserSettings(val: any[]) {
		this.userSettings = val;
	}

	public getUserSettings() {
		return this.userSettings || [];
	}

	// Method used to get the Revenue configurations
	public fetchUserSettings(sectionName?: string) {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.USER_SETTINGS);
		if (sectionName) {
			formData.append('section', sectionName); // Not Mendatory
		}
		return this._RestApiService.doDataOutReqFormData(formData);
	}

	// Method used to get the Revenue configurations
	public fetchCustomerData(entity?: string) {
		const viewFields = ['guid', 'code', 'name', 'crm_type', 'human_readable_account_code'];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		formData.append('entity', entity || NameFromServerKeyEnum.CLIENT);
		formData.append('view_fields', JSON.stringify(viewFields));
		formData.append('skip', '0');
		formData.append('take', '1');
		formData.append('requireTotalCount', 'true');
		return this._RestApiService.doDataOutReqFormData(formData);
	}

	public getSectionFromUserSettings(setctionKey: string) {
		if (this.isCheckSectionInUserSettings(setctionKey)) {
			if (this.userSettings && this.userSettings.length > 0) {
				return this.userSettings[0][setctionKey];
			} else {
				return {};
			}
		} else {
			return {};
		}
	}

	public setSectionFromUserSettings(sectionKey: string, sectionValue: any) {
		if (this.userSettings && this.userSettings.length > 0) {
			this.userSettings[0][sectionKey] = sectionValue;
		} else {
			console.log('User Settings Not Availabled.');
		}
	}

	public isCheckSectionInUserSettings(sectionKey: string) {
		if (this.userSettings && this.userSettings.length > 0) {
			return this.userSettings[0].hasOwnProperty(sectionKey);
		} else {
			console.log('User Settings Not Availabled.');
			return false;
		}
	}

	public doClearAllSetterGetter() {
		this._CrmsService.doClearSetterGetter();
		this._SalesDataSharingService.doClearAllSetterGetter();
		this.setUserSettings([]);
		this._AppStateKeyVal = {};
	}

	public getAppState(prop?: any) {
		return this._AppStateKeyVal.hasOwnProperty(prop) ? this._AppStateKeyVal[prop] : this._AppStateKeyVal;
	}

	public setAppState(prop: string, value: any) {
		this._AppStateKeyVal[prop] = value;
	}

	public doRemoveAppState(prop: string) {
		delete this._AppStateKeyVal[prop];
	}

	public resetAppState(prop?: string) {
		if (prop) {
			this._AppStateKeyVal[prop] = {};
		} else {
			this._AppStateKeyVal = {};
		}
	}

	public getListOfNotifyBy() {
		return [
			{
				text: 'Email',
				value: 'email'
			},
			{
				text: 'SMS',
				value: 'sms'
			}
		];
	}

	public getListOfNotifyNoOfDays() {
		return [
			{
				text: 'Sunday',
				sort_text: 'Sun',
				value: 'sunday'
			},
			{
				text: 'Monday',
				sort_text: 'Mon',
				value: 'monday'
			},
			{
				text: 'Tuesday',
				sort_text: 'Tue',
				value: 'tuesday'
			},
			{
				text: 'Wednesday',
				sort_text: 'Wed',
				value: 'wednesday'
			},
			{
				text: 'Thursday',
				sort_text: 'Thu',
				value: 'thursday'
			},
			{
				text: 'Friday',
				sort_text: 'Fri',
				value: 'friday'
			},
			{
				text: 'Saturday',
				sort_text: 'Sat',
				value: 'saturday'
			}
		];
	}

	public getListOfNotifyDuration() {
		return [
			{
				text: 'Minutes',
				sort_text: 'Min(s)',
				value: 'minutes'
			},
			{
				text: 'Hours',
				sort_text: 'Hr(s)',
				value: 'hours'
			}
		];
	}

	// Method used to get Output : 10:10 AM | PM
	public doConvertToDateTime(timeWithAmPm) {
		const newDate = new Date();
		const splitBySpace = timeWithAmPm.split(' ');
		if (splitBySpace && splitBySpace.length > 0) {
			const splitByColon = splitBySpace[0].split(':');
			if (splitByColon && splitByColon.length > 0) {
				let hours = +splitByColon[0];
				const minutes = +splitByColon[1];
				newDate.setHours(hours);
				newDate.setMinutes(minutes);
				newDate.setSeconds(0);
				if (splitBySpace[1] === 'AM') {
					if (hours === 12) {
						hours = hours - 12;
						newDate.setHours(hours);
					}
				}
				if (splitBySpace[1] === 'PM') {
					if (hours < 12) {
						hours = hours + 12;
						newDate.setHours(hours);
					}
				}
			}
		}
		return newDate;
	}

	// Method used to get Output : 10:10 AM | PM
	public doConvertTimeIntoAmPm(date): string {
		let hours = date.getHours();
		let minutes = date.getMinutes();
		const ampm = hours >= 12 ? 'PM' : 'AM';
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? '0' + minutes : minutes;
		const strTime = hours + ':' + minutes + ' ' + ampm;
		return strTime;
	}

	public setPopupHeightWidthRatio(heightWidth: any) {
		this.popupHeightWidth = heightWidth;
	}
	public getPopupHeightWidthRatio() {
		return this.popupHeightWidth;
	}

	public getFeatureGuid() {
		let returnVal = '';
		if (this._LoginService.loginUser.account_detail['enable_invoice_custom_fields_feature']) {
			returnVal = 'bc541207c8644ec3b14fe1ade63460b9'
		}
		if (this._LoginService.loginUser.account_detail['enable_profit_on_document_header']) {
			returnVal = 'ffc6f6d2127d41a7b4a3e358c8389912'
		}
		return returnVal;
	}

	public fetchListOfFetureCustomFields() {
		const feature_guid = this.getFeatureGuid() || '';
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.FEATURE_CUSTOM_FIELDS);
		if (feature_guid) {
			formData.append('search', JSON.stringify([{ feature_guid: feature_guid }]));
		}
		formData.append('is_dropdown', 'true');
		return this._RestApiService.doDataOutReqFormData(formData);
	}

	public setFeatureCustomFields(featureCustomFields: any[]) {
		this.listOfFeatureCustomFields = featureCustomFields;
	}
	public getFeatureCustomFields() {
		return this.listOfFeatureCustomFields;
	}

	public getListOfActionsForClaimPreviewDatagrid() {
		return [
			// {
			// 	btn_code: 'ADD',
			// 	btn_name: 'Add New',
			// 	btn_image: IMG_ADD_FILE_SVG,
			// 	icon: ' ' + IMG_ADD_FILE_SVG,
			// 	visible: true,
			// 	disabled: false,
			// },
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	downloadAnyBlobFile(data: Blob, fileName: string, fileType?: string) {
		let type = { type: '' };
		if (fileType) {
			if (fileType.toString().toUpperCase() === 'PDF') {
				type = { type: 'application/pdf' };
			} else if (fileType.toString().toUpperCase() === 'EXCEL') {
				type = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
			} else if (fileType.toString().toUpperCase() === 'WORD') {
				type = { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' };
			}

		}
		saveAs(new Blob([data], type), fileName);
	}

	public generatePassword() {
		let lowerCharacters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
		let upperCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
		let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
		let symbols = ['!', '"', '#', '$', '%', '&', '\'', '(', ')', '*', '+', ',', '-', '.', '/', ':', ';', '<', '=', '>', '?', '@', '[', ']', '^', '_', '`', '{', '|', '}', '~'];
		let finalCharacters = lowerCharacters;
		finalCharacters = finalCharacters.concat(upperCharacters);
		finalCharacters = finalCharacters.concat(numbers);
		finalCharacters = finalCharacters.concat(symbols);
		let passwordArray = [];
		for (let i = 0; i < this.passwordLength; i++) {
			passwordArray.push(finalCharacters[Math.floor(Math.random() * finalCharacters.length)]);
		};
		const newGeneratedpassword = passwordArray.join("");
		let pwStrengthTxt: string = this.passWordAanalyze(newGeneratedpassword);
		if (pwStrengthTxt == "Medium" || pwStrengthTxt == "Weak") {
			passwordArray = [];
			this.generatePassword();
			return;
		}
		return newGeneratedpassword;
	}

	public passWordAanalyze(value) {
		var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!@#\"\$%\^&\*()_+|=\\]\\[{}~`:\";\'<>?,.\/])(?=.{8,})");
		var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
		let txt, color;
		if (strongRegex.test(value)) {
			color = "#57A83F";
			txt = "Strong";
		} else if (mediumRegex.test(value)) {
			color = "#FF9800";
			txt = "Medium";
		} else {
			color = "#E03930";
			txt = "Weak";
		}
		return txt;
	}

	public chkHasMinLength(password, isValChange?: boolean) {
		if (isValChange) {
			return (password.length >= this.passwordLength) ? 'T' : 'F';
		} else {
			return (password.length >= this.passwordLength) ? 'T' : '';
		}
	}
	public chkHasNumber(password, isValChange?: boolean) {
		if (isValChange) {
			return (/\d/.test(password)) ? 'T' : 'F';
		} else {
			return (/\d/.test(password)) ? 'T' : '';
		}
	}
	public chkHasUpper(password, isValChange?: boolean) {
		if (isValChange) {
			return (/[A-Z]/.test(password)) ? 'T' : 'F';
		} else {
			return (/[A-Z]/.test(password)) ? 'T' : '';
		}
	}
	public chkHasLower(password, isValChange?: boolean) {
		if (isValChange) {
			return (/[a-z]/.test(password)) ? 'T' : 'F';
		} else {
			return (/[a-z]/.test(password)) ? 'T' : '';
		}
	}
	public chkHasNonalphas(password, isValChange?: boolean) {
		if (isValChange) {
			return (/\W/.test(password)) ? 'T' : 'F';
		} else {
			return (/\W/.test(password)) ? 'T' : '';
		}

	}

	fetchedListOfCurrrency(): Observable<any> {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('entity', ServerEntity.CURRENCY);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		return this._RestApiService.doDataOutReqFormData(formData)
	}
	fetchedListOfOtherCurrrency(): Observable<any> {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('entity', ServerEntity.OTHER_CURRENCIES);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		return this._RestApiService.doDataOutReqFormData(formData)
	}


	public getCurrencyDetail(currencyOptions?: CurrencyOptions) {
		let currencyObj: CurrencyModel = new CurrencyModel();
		let listOfOtherCurrency: any[] = [];
		if (this._LoginService.loginUser.account_detail.hasOwnProperty('other_currencies_lst')) {
			listOfOtherCurrency = this._LoginService.loginUser.account_detail.other_currencies_lst || [];
		}
		const currencyIndex = listOfOtherCurrency.findIndex(i => i.currency_code === currencyOptions.currencyCode || i.erp_currency_name === currencyOptions.currencyName);
		if (currencyIndex !== -1) {
			currencyObj = listOfOtherCurrency[currencyIndex];
		}
		return currencyObj;
	}

	public chkEnabledDistributionChannel(): boolean {
		let isEnableDistributionChannels = false;
		try {
			isEnableDistributionChannels = this._LoginService.loginUser.account_detail.enable_distribution_channels;
		} catch (e) {
			isEnableDistributionChannels = false;
		}
		return isEnableDistributionChannels;
	}
}
