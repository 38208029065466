import { MmeuccItemsTabComponent } from './mmeucc-items-tab/mmeucc-items-tab.component';
import * as moment from 'moment';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from '@app/custom-components/confirmation-dialog/confirmation-dialog.service';
import { MinMaxEndUsersChargebackContractModelRequest, MinMaxEndUsersChargebackContractModelUI } from '@app/models/min-max-end-users-chargeback-contract.model';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { DataService } from '@app/services/data.service';
import { FileUploadService } from '@app/services/file-upload.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { BillbackService } from '@app/services/contract-service/billback/billback.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent } from 'devextreme-angular';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { ContractTypeValEnum, DistributionChannelsCallFromViewEnum, ErpTypeValEnum } from '@app/constants-enums/enums';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CUSTOM_DATE_FORMATS, MSG_ERROR_MESSAGE, ServerAction, ServerEntity, ServerMethods, ServerMode } from '@app/constants-enums/constants';
import { PageOptions } from '@app/models/common.model';
import { Subscription } from 'rxjs';
import { MinMaxEndUsersChargebackContractService } from '@app/services/contract-service/billback/min-max-end-users-chargeback-contract.service';
import { ChargebackContractService } from '@app/services/contract-service/billback/chargeback-contract.service';
import { BillbackContractService } from '@app/services/contract-service/billback/billback-contract.service';
import { MmeuccChargebackDocumentsTabComponent } from './mmeucc-chargeback-documents-tab/mmeucc-chargeback-documents-tab.component';
import { DistributionChargebackContractService } from '@app/services/contract-service/billback/distribution-chargeback-contract.service';
import { MmeuccPricelistTabComponent } from './mmeucc-pricelist-tab/mmeucc-pricelist-tab.component';
import { IxDxAlertService } from '@app/services/ix-dx-alert-services/ix-dx-alert-services';
import { EndUsersChargebackContractService } from '@app/services/contract-service/billback/end-users-chargeback-contract.service';
import { MmeuccCustomersPricelistTabComponent } from './mmeucc-customers-pricelist-tab/mmeucc-customers-pricelist-tab.component';
import { DistributionChannelsDxDropDownBoxModel } from '@app/models/input-Components.model';
@Component({
	selector: 'app-min-max-end-users-chargeback-contract',
	templateUrl: './min-max-end-users-chargeback-contract.component.html',
	styleUrls: ['./min-max-end-users-chargeback-contract.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
	]
})
export class MinMaxEndUsersChargebackContractComponent implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild("customerDataGrid", {}) customerDataGrid: DxDataGridComponent;
	@ViewChild("pricelistDataGrid", {}) pricelistDataGrid: DxDataGridComponent;
	@ViewChild("itemDataGrid", {}) itemDataGrid: DxDataGridComponent;
	@ViewChild(MmeuccItemsTabComponent) itemTabComponentRef;
	@ViewChild(MmeuccPricelistTabComponent) priceListTabComponentRef;
	private dataInReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;
	public erp_type: string;
	public isQBOnlineErpUser: boolean = false;
	public isQBErpUser: boolean = false;
	public permsn: any = [];
	public editChargebackGuid: string;
	public productSearchItemType: string;
	public isDisablePermission: boolean = false;
	public isCurrentTabPermission: string;
	public selectedTabVal: string;
	public tabItemsList: any[] = [];
	public selectCustomerPriceList: any;
	public isCustomerPriceListChanged: boolean = false;

	// Select box list customer
	public customerDataSource: any;
	public customerGridBoxValue: any[] = [];
	public customerGridBoxColumns: any;
	public isCustomerDropDownBoxOpened: boolean = false;
	// Select box list customer
	public pricelistDataSource: any;
	public pricelistGridBoxValue: any[] = [];
	public pricelistGridBoxColumns: any;
	public isPricelistDropDownBoxOpened: boolean = false;

	// Model object
	public guid = new Guid();
	public pageOptions: PageOptions = new PageOptions();
	public minMaxEndUsersChargebackContractModelUI = new MinMaxEndUsersChargebackContractModelUI();
	public minMaxEndUsersChargebackContractModelRequest = new MinMaxEndUsersChargebackContractModelRequest();
	public itemTabDataList: any = [];
	public itemTabDataSource: any;
	public chargeBackDocumentsTabDatalist: any[] = [];

	private getCustomerPriceLstSbsn: Subscription;
	private getPriceLstSbsn: Subscription;
	private getItemDetailsSbsn: Subscription;
	private getUomLstSbsn: Subscription;
	private getUomGroupLstSbsn: Subscription;
	public listOfPrices: any[] = [];
	public listOfUoms: any[] = [];
	public listOfUomGroups: any[] = [];
	public showNotification: any;
	public selectCustomerDetails: any;
	public billbackTypeRadio: any[];
	public chargebackBasisRadio: any[];
	public itemDataSource: any;
	public isCustomerChargebackbasis: boolean = false;
	@ViewChild(MmeuccItemsTabComponent) childEuccItemsTabComponent: MmeuccItemsTabComponent;
	@ViewChild(MmeuccChargebackDocumentsTabComponent) childEuccChargebackDocumentsTabComponent: MmeuccChargebackDocumentsTabComponent;
	@ViewChild(MmeuccCustomersPricelistTabComponent) childMmeuccCustomersPricelistTabComponent: MmeuccCustomersPricelistTabComponent;
	public isCondOfViewCustPricelistTab: boolean = false;
	public isCondOfViewEndUsersPricelistTab: boolean = false;
	public distributionChannelsModel: DistributionChannelsDxDropDownBoxModel = new DistributionChannelsDxDropDownBoxModel();
	public isEnableDistributionChannels: boolean = this._AppCommonSrvc.chkEnabledDistributionChannel();
	constructor(
		public _MatDialog: MatDialog,
		private _Router: Router,
		private _ToastrService: ToastrService,
		private _PermissionsService: NgxPermissionsService,
		private _LoginService: LoginService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _ConfirmationDialogService: ConfirmationDialogService,
		private _DataService: DataService,
		private _MinMaxEndUsersChargebackContractService: MinMaxEndUsersChargebackContractService,
		private _ChargebackContractService: ChargebackContractService,
		private _EndUsersChargebackContractService: EndUsersChargebackContractService,
		private _BillbackContractService: BillbackContractService,
		private _DistributionChargebackContractService: DistributionChargebackContractService,
		private _FileUploadService: FileUploadService,
		private _GlobalStateService: GlobalStateService,
		public _AppCommonSrvc: AppCommonSrvc,
		public _BillbackService: BillbackService,
		private _ChangeDetectorRef: ChangeDetectorRef,
		private _IxDxAlertService: IxDxAlertService
	) { }

	ngOnInit() {
		// get current user Erp type
		if (this._LoginService.loginUser && this._LoginService.loginUser.account_detail && this._LoginService.loginUser.account_detail.app_settings) {
			this.erp_type = this._LoginService.loginUser.account_detail.app_settings.erp_type;
		}
		// check Is QB User (no need to view code of QB user)
		this.isQBOnlineErpUser = this._BillbackService.getQbOnlineErpUser();
		this.isQBErpUser = this._BillbackService.getQbErpUser();
		this.customerGridBoxColumns = this._BillbackService.getCustomerGridBoxColumn();
		this.pricelistGridBoxColumns = this._BillbackService.getPriceListGridBoxColumn();
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		for (const per in permissions) {
			this.permsn.push(per);
		}
		// End Get current permissions
		this.editChargebackGuid = this._MinMaxEndUsersChargebackContractService.getEditMinMaxEndUsersChargebackGuid();
		if (this.permsn.indexOf('INCENTIVE_BILLBACKCHARGEBACK_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_BILLBACKCHARGEBACK_MODIFY';
		}
		this.preInit();
	}

	ngOnDestroy() {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.getPriceLstSbsn) {
			this.getPriceLstSbsn.unsubscribe();
		}
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		this._GlobalStateService.unsubscribe('DISABLED_DISTRIBUTION_CHANNEL_' + ContractTypeValEnum.MINMAXENDUSERSCHARGEBACKCONTRACTS);
	}

	ngAfterContentChecked() {
		this._ChangeDetectorRef.detectChanges();
	}

	preInit() {
		this._MinMaxEndUsersChargebackContractService.setItemTabDataList([]);
		this.billbackTypeRadio = this._BillbackService.fetchBillbackTypeListRadio();
		this.minMaxEndUsersChargebackContractModelUI.billBackType = 'minmaxenduserschargeback';
		this.tabItemsList = this._MinMaxEndUsersChargebackContractService.tabItemsList;
		this.productSearchItemType = this._BillbackService.getProductSearchType();
		this.chargebackBasisRadio = this._MinMaxEndUsersChargebackContractService.listOfCharegbackBasis;
		if (!this.minMaxEndUsersChargebackContractModelUI.chargebackBasis) {
			this.minMaxEndUsersChargebackContractModelUI.chargebackBasis = this._MinMaxEndUsersChargebackContractService.listOfCharegbackBasis[0].value || 'endusers';
		}
		// DistributionChannel drop-down list
		this.loadDistributionChannel();
		this._GlobalStateService.unsubscribe('DISABLED_DISTRIBUTION_CHANNEL_' + ContractTypeValEnum.MINMAXENDUSERSCHARGEBACKCONTRACTS);
		this._GlobalStateService.subscribe('DISABLED_DISTRIBUTION_CHANNEL_' + ContractTypeValEnum.MINMAXENDUSERSCHARGEBACKCONTRACTS, response => {
			if (response) {
				this.distributionChannelsModel.isDisabledControl = response.isDisabled;
			}
		});
		this._BillbackContractService.getChargebackDocumentLst(this.editChargebackGuid, ContractTypeValEnum.MINMAXENDUSERSCHARGEBACKCONTRACTS);
		//End DistributionChannel drop-down list
		this.loadCustomerDataList();
		this.loadPricelistDataList();
		if (this.editChargebackGuid) {
			// this.selectedTabVal = 'items';
			this.editChargebackContract();
		} else {
			this.selectedTabVal = 'items';
		}
		this.fetchListOfUoms();
		this.fetchListOfUomGroups();
		this.disableChargebackInputFields();
		this.chargebackBasisCondtions();
	}

	ngAfterViewInit() {
		this.loadCondtionsForHTML();
	}

	// Set Settlement StartDate and EndDate
	compareTwoDates() {
		// check start date and end date validate
		if (new Date(this.minMaxEndUsersChargebackContractModelUI.endDate) < new Date(this.minMaxEndUsersChargebackContractModelUI.startDate)) {
			this._IxDxAlertService.doShowAlert("End Date can't before start date").then(result => {
			});
		}
	}

	disableChargebackInputFields() {
		if (this.editChargebackGuid) {
			this.isDisablePermission = true;
		} else {
			this.isDisablePermission = false;
		}

	}
	// All condtion as on chargeback basis 
	chargebackBasisCondtions() {
		this.isCustomerChargebackbasis = false;
		const chargebackBasis = this.minMaxEndUsersChargebackContractModelUI.chargebackBasis || 'endusers';
		const customerBasis = this._MinMaxEndUsersChargebackContractService.listOfCharegbackBasis[1].value || 'customers'
		if (chargebackBasis && chargebackBasis == customerBasis) {
			this.isCustomerChargebackbasis = true;
		}
	}
	//#region for Customer select box methods
	public loadCustomerDataList() {
		const viewFields = ['guid', 'code', 'name', 'taxcode', 'taxexempt', 'pricelistname', 'pricelistnum'];
		if (this.isEnableDistributionChannels) {
			viewFields.push('distribution_channels');
		}
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.CLIENTS,
			view_fields: JSON.stringify(viewFields),
		};
		this.customerDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: 'code',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
		});
	}

	public onOptionChangedCustomer(e) {
		if (e.name === "value") {
			if (this.customerDataGrid) {
				const selectedCustomerArr = this.customerDataGrid.instance.getSelectedRowsData();
				if (selectedCustomerArr && selectedCustomerArr.length > 0) {
					const selectedCustomerObj = selectedCustomerArr[0];
					if (selectedCustomerObj) {
						this.minMaxEndUsersChargebackContractModelUI.selectCustomerId = selectedCustomerObj.code;
						this.minMaxEndUsersChargebackContractModelUI.selectCustomer = selectedCustomerObj.name;
						//set distribution channels as per selected customer
						if (this.isEnableDistributionChannels) {
							if (selectedCustomerObj.hasOwnProperty('distribution_channels') && selectedCustomerObj.distribution_channels.length > 0) {
								let lstCustAssignDC: any = [];
								selectedCustomerObj.distribution_channels.forEach(dc => {
									const indexDC = this.distributionChannelsModel.distributionChannelsDataSource.findIndex(i => i.code === dc);
									if (indexDC != -1) {
										lstCustAssignDC.push(this.distributionChannelsModel.distributionChannelsDataSource[indexDC]);
									}
								});
								this.distributionChannelsModel.distributionChannelsDataSource = JSON.parse(JSON.stringify(lstCustAssignDC));
							} else {
								this.distributionChannelsModel.distributionChannelsDataSource = [];
							}
						}
						//End set distribution channels as per selected customer
						if (this.productSearchItemType == 'item') {
							this.itemTabComponentRef.loadItemTabDataList();
						}
					}
				} else {
					this.distributionChannelsModel.distributionChannelsDataSource = [];
				}
			}
			this.isCustomerDropDownBoxOpened = false;
			this._ChangeDetectorRef.detectChanges();
		}

	}

	onCustomerValueChanged(e: any) {
		if (e.value === null) {
			this.minMaxEndUsersChargebackContractModelUI.selectCustomerId = '';
			this.minMaxEndUsersChargebackContractModelUI.selectCustomer = '';
			this.customerGridBoxValue = [];
			if (this.customerDataGrid) {
				this.customerDataGrid.instance.refresh();
			}
		} else {
			if (this.customerDataGrid) {
				const selectedCustomerArr = this.customerDataGrid.instance.getSelectedRowsData();
				if (selectedCustomerArr && selectedCustomerArr.length > 0) {
					const selectedCustomerObj = selectedCustomerArr[0];
					// chargeback basis as customer fetch customer pricelist name
					if (this.isCustomerChargebackbasis) {
						if (selectedCustomerObj.hasOwnProperty('pricelistnum')) {
							if (!selectedCustomerObj.pricelistnum) {
								this._ToastrService.info('Customer Pricelist  is required!', 'Info!', { closeButton: true, tapToDismiss: true });
								return false;
							}
						}
						//this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListNum = selectedCustomerObj.pricelistnum;
						this.fetchedListOfPrices(selectedCustomerObj.pricelistnum, false, 'customer');
					}
				}
			}
		}
	}

	public displayExprCustomerName(customer) {
		if (!customer) {
			return "";
		}
		const name = customer.name;

		return name;
	}
	//#endregion

	//#region for Pricelist select box methods
	public loadPricelistDataList() {
		const viewFields = ['unique_id', 'pricelistno', 'pricelistname'];
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.PRICELISTS,
			view_fields: JSON.stringify(viewFields),
		};
		this.pricelistDataSource = AspNetData.createStore({
			loadMode: "processed",
			key: 'pricelistno',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
		});
	}

	public onOptionChangedPricelist(e) {
		if (e.name === "value") {
			if (this.pricelistDataGrid) {
				const selectedPricelistArr = this.pricelistDataGrid.instance.getSelectedRowsData();
				if (selectedPricelistArr && selectedPricelistArr.length > 0) {
					const selectedPricelistObj = selectedPricelistArr[0];
					if (selectedPricelistObj) {
						this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListNum = selectedPricelistObj.pricelistno;
						this.fetchedListOfPrices(this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListNum, false);
					}
				}
			}
			this.isPricelistDropDownBoxOpened = false;
			this._ChangeDetectorRef.detectChanges();
		}

	}

	onPricelistValueChanged(e: any) {
		if (e.value === null) {
			this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListNum = '';
			this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListName = '';
			this.pricelistGridBoxValue = [];
			if (this.pricelistDataGrid) {
				this.pricelistDataGrid.instance.refresh();
			}
		}
	}

	public displayExprPricelistName(pricelist) {
		if (!pricelist) {
			return "";
		}
		const pricelistname = pricelist.pricelistname;
		return pricelistname;
	}
	//#endregion

	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uom');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		// this._LoaderService.show();
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				// this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUoms = response.data;
					}
				}
			}, error: (error) => {
				this._LoaderService.hide();
			}
		});
	}

	// Method used to get Uo Group List
	private fetchListOfUomGroups() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uomgroups');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name', 'baseuom', 'uomgroupdefinitioncollection']));
		formData.append('is_dropdown', 'true');

		// this._LoaderService.show();
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		this.getUomGroupLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				// this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUomGroups = response.data;
					}
				}
			}, error: (error) => {
				this._LoaderService.hide();
			}
		});
	}

	public doChangeBillBackType(e) {
		this.minMaxEndUsersChargebackContractModelUI.billBackType = e.value;
		this._BillbackService.setBillbackType(this.minMaxEndUsersChargebackContractModelUI.billBackType);
		switch (this.minMaxEndUsersChargebackContractModelUI.billBackType) {
			case 'billback':
				this._BillbackContractService.setEditBillbackGuid('');
				this._Router.navigate(['/contracts/billbackchargeback/billback-contract/']);
				break;
			case 'chargeback':
				this._ChargebackContractService.setEditChargebackGuid('');
				this._Router.navigate(['/contracts/billbackchargeback/chargeback-contract/']);
				break;
			case 'enduserschargeback':
				this._EndUsersChargebackContractService.setEditEndUsersChargebackGuid('');
				this._Router.navigate(['/contracts/billbackchargeback/end-users-chargeback-contract/']);
				break;
			case 'distributionchargeback':
				this._DistributionChargebackContractService.setEditDistributionChargebackGuid('');
				this._Router.navigate(['/contracts/billbackchargeback/distribution-chargeback-contract/']);
				break;
			case 'minmaxenduserschargeback':
				// this._MinMaxEndUsersChargebackContractService.setMinMaxEditEndUsersChargebackGuid('');
				// this._Router.navigate(['/contracts/billbackchargeback/min-max-end-users-chargeback-contract/']);
				break;
		}
	}

	public doChangeChargebackBasis(e) {
		if (!this.editChargebackGuid) {
			this.minMaxEndUsersChargebackContractModelUI.selectCustomer = '';
			this.minMaxEndUsersChargebackContractModelUI.selectCustomerId = '';
			this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListNum = '';
			this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListName = '';
			if (this.customerGridBoxValue && this.customerGridBoxValue.length) {
				this.customerGridBoxValue = [];
			}
			if (this.pricelistGridBoxValue && this.pricelistGridBoxValue.length) {
				this.pricelistGridBoxValue = [];
			}
			if (this.itemTabDataList && this.itemTabDataList.length > 0) {
				this.itemTabDataList = [];
				this.itemTabDataSource = [];
			}
		}
		this.minMaxEndUsersChargebackContractModelUI.chargebackBasis = e.value;
		this.chargebackBasisCondtions();
		this.loadCondtionsForHTML();
	}

	public doClickTab(val) {
		if (this.selectedTabVal === val) {
			return;
		}
		this.selectedTabVal = val;
		this.loadCondtionsForHTML();
		if (val === 'items' && this.editChargebackGuid) {
			this.editChargebackContract('edit');
		}
	}

	public checkValidation(): boolean {
		if (!this.minMaxEndUsersChargebackContractModelUI.contractName) {
			this._ToastrService.info('Contract Name is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
		if (!this.minMaxEndUsersChargebackContractModelUI.selectCustomer) {
			this._ToastrService.info('Customer is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
		if (!this.minMaxEndUsersChargebackContractModelUI.startDate) {
			this._ToastrService.info('Start Date is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
		if (!this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListNum) {
			this._ToastrService.info('Pricelist is Required.', 'Info', { closeButton: true, tapToDismiss: true });
			return true;
		}
		if (this.isEnableDistributionChannels) {
			if (this.distributionChannelsModel.arrSelectedDistributionChannelsList.length === 0) {
				this._ToastrService.info('Distribution Channel is Required.', 'Info', { closeButton: true, tapToDismiss: true });
				return true;
			}
		}
	}

	public doSaveMinMaxEndUsersChargebackContract(isProductSave?: boolean) {
		if (this.checkValidation()) {
			return false;
		}

		this.minMaxEndUsersChargebackContractModelRequest = new MinMaxEndUsersChargebackContractModelRequest();
		if (this._MinMaxEndUsersChargebackContractService.getEditMinMaxEndUsersChargebackGuid()) {
			this.minMaxEndUsersChargebackContractModelRequest.guid = this._MinMaxEndUsersChargebackContractService.getEditMinMaxEndUsersChargebackGuid();
		} else {
			this.minMaxEndUsersChargebackContractModelRequest.guid = this.guid.newGuid();
		}
		// const itemTabDataList: any = this._MinMaxEndUsersChargebackContractService.getItemTabDataList() || [];
		// if (itemTabDataList && itemTabDataList.length > 0) {
		// 	this.itemTabDataList = itemTabDataList;
		// }
		this.minMaxEndUsersChargebackContractModelRequest.ixcode = this.minMaxEndUsersChargebackContractModelUI.ixcode;
		this.minMaxEndUsersChargebackContractModelRequest.contractName = this.minMaxEndUsersChargebackContractModelUI.contractName;
		this.minMaxEndUsersChargebackContractModelRequest.chargebackBasis = this.minMaxEndUsersChargebackContractModelUI.chargebackBasis || 'endusers';
		this.minMaxEndUsersChargebackContractModelRequest.selectCustomerId = this.minMaxEndUsersChargebackContractModelUI.selectCustomerId;
		this.minMaxEndUsersChargebackContractModelRequest.selectCustomer = this.minMaxEndUsersChargebackContractModelUI.selectCustomer;
		this.minMaxEndUsersChargebackContractModelRequest.selectCustomerPriceListNum = this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListNum;
		this.minMaxEndUsersChargebackContractModelRequest.selectCustomerPriceListName = this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListName;
		this.minMaxEndUsersChargebackContractModelRequest.startDate = moment(this.minMaxEndUsersChargebackContractModelUI.startDate).format('YYYY-MM-DD') || '';
		this.minMaxEndUsersChargebackContractModelRequest.endDate = moment(this.minMaxEndUsersChargebackContractModelUI.endDate).format('YYYY-MM-DD') || '';
		this.minMaxEndUsersChargebackContractModelRequest.month_filter = moment(this.minMaxEndUsersChargebackContractModelUI.month_filter).format('YYYY-MM-DD') || '';
		this.minMaxEndUsersChargebackContractModelRequest.billBackType = this.minMaxEndUsersChargebackContractModelUI.billBackType;
		this.minMaxEndUsersChargebackContractModelRequest.is_active = true;
		if (this.isEnableDistributionChannels) {
			this.minMaxEndUsersChargebackContractModelRequest.distribution_channels = this.distributionChannelsModel.arrSelectedDistributionChannelsList.length > 0 ? this.distributionChannelsModel.arrSelectedDistributionChannelsList[0] : '';
		}

		if (this.productSearchItemType === 'price') {
			this.minMaxEndUsersChargebackContractModelUI.pricelist_modified_date = this.listOfPrices && this.listOfPrices[0] ? this.listOfPrices[0].modified_date : '';
			this.minMaxEndUsersChargebackContractModelRequest.pricelist_modified_date = this.minMaxEndUsersChargebackContractModelUI.pricelist_modified_date;
			this.minMaxEndUsersChargebackContractModelRequest.pricelist_modified_user_action = this.minMaxEndUsersChargebackContractModelUI.pricelist_modified_user_action;
		}
		if (this.editChargebackGuid) {
			this.callSaveChargebackContractNew(this.minMaxEndUsersChargebackContractModelRequest);
		} else {
			if (this.isCustomerChargebackbasis) {
				this.minMaxEndUsersChargebackContractModelRequest.customers = this.itemTabDataList || [];
			} else {
				this.minMaxEndUsersChargebackContractModelRequest.endusers = this.itemTabDataList || [];
			}
			// this.minMaxEndUsersChargebackContractModelRequest.chargeDocuments = this.chargeBackDocumentsTabDatalist || [];
			const arryBillBackContracts = [];
			arryBillBackContracts.push(JSON.parse(JSON.stringify(this.minMaxEndUsersChargebackContractModelRequest)));
			this.callSaveChargebackContract(arryBillBackContracts);
		}
	}

	public doSaveMinMaxEndUsersChargebackItemTabData(event) {
		// upload item did not get new list for item tab 
		if (event === 'uploaditem') {
			this.itemTabDataList = this._MinMaxEndUsersChargebackContractService.getItemTabDataList();
		}
		if (event === 'claimItemChargeback') {
			this.showNotification = "No";
			this.itemTabDataList = this._MinMaxEndUsersChargebackContractService.getItemTabDataList();
			this.chargeBackDocumentsTabDatalist = this._MinMaxEndUsersChargebackContractService.getItemTabDataList();
			this.itemTabDataSource = this.itemTabDataList;
		}
		if (event === 'monthFilter') {
			this.showNotification = "No";
		}

		this.doSaveMinMaxEndUsersChargebackContract();
	}

	public callSaveChargebackContract(arryChargebackContract: any[]) {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('minmaxenduserschargebackcontracts', JSON.stringify(arryChargebackContract));
		this._LoaderService.show();
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq((formData)).subscribe({
			next: (response) => {
				if (response.flag) {
					if (this.showNotification !== 'No') {
						this._ToastrService.success('Save Successfully', 'Saved', { closeButton: true, tapToDismiss: true });
					}
					const objBillbackContracts = response.data.minmaxenduserschargebackcontracts;
					let billbackContracts: any;
					if (objBillbackContracts && objBillbackContracts.length > 0) {
						billbackContracts = objBillbackContracts[0];
						// if (billbackContracts && billbackContracts.chargebackItems) {
						// 	this.itemTabDataList = billbackContracts.chargebackItems;
						// 	this.itemTabDataSource = billbackContracts.chargebackItems;
						// 	if (this.priceListTabComponentRef && this.productSearchItemType === 'price') {
						// 		this.priceListTabComponentRef.getListPriceOfItems(this.itemTabDataList);
						// 	}
						// }
						this._MinMaxEndUsersChargebackContractService.setEditMinMaxEndUsersChargebackGuid(billbackContracts.guid);
						this.editChargebackGuid = billbackContracts.guid;
						this.minMaxEndUsersChargebackContractModelUI.guid = billbackContracts.guid;
						this.minMaxEndUsersChargebackContractModelRequest.guid = billbackContracts.guid;
						this.minMaxEndUsersChargebackContractModelRequest.ixcode = billbackContracts.ixcode;
						// if (this.editChargebackGuid) {
						// 	this.editChargebackContract('edit');
						// }
					}
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				//this._MinMaxEndUsersChargebackContractService.setItemTabDataList([]);
				this._LoaderService.hide();
				this.disableChargebackInputFields();
			}, error: (error) => {
				// this._MinMaxEndUsersChargebackContractService.setItemTabDataList([]);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			}
		});
	}

	public callSaveChargebackContractNew(objChargebackContract: any) {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.IX_UPDATE_ENTITY_DATA);
		formData.append('entity', ServerEntity.MINMAXENDUSERSCHARGEBACKCONTRACTS);
		formData.append('guid', this.editChargebackGuid);
		formData.append('mode', ServerMode.HEADER);
		formData.append('action', ServerAction.EDIT);
		formData.append('updatedData', JSON.stringify(objChargebackContract));
		this._LoaderService.show();
		this._RestApiService.doDataInFormDataReq((formData)).subscribe({
			next: (response) => {
				if (response.flag) {
					if (this.showNotification !== 'No') {
						this._ToastrService.success('Save Successfully', 'Saved', { closeButton: true, tapToDismiss: true });
					} else {
						this._GlobalStateService.notifyDataChangedDuplicate('CALLED_COLLAPSE_DATAGRID', { ts: new Date().getTime() });
					}
					const objBillbackContracts = response.data.minmaxenduserschargebackcontracts;
					let billbackContracts: any;
					// if (objBillbackContracts && objBillbackContracts.length > 0) {
					// 	billbackContracts = objBillbackContracts[0];
					// 	if (billbackContracts && billbackContracts.chargebackItems) {
					// 		this.itemTabDataList = billbackContracts.chargebackItems;
					// 		this.itemTabDataSource = billbackContracts.chargebackItems;
					// 		if (this.priceListTabComponentRef && this.productSearchItemType === 'price') {
					// 			this.priceListTabComponentRef.getListPriceOfItems(this.itemTabDataList);
					// 		}
					// 	}
					// 	this._MinMaxEndUsersChargebackContractService.setEditMinMaxEndUsersChargebackGuid(billbackContracts.guid);
					// 	this.editChargebackGuid = billbackContracts.guid;
					// 	this.minMaxEndUsersChargebackContractModelUI.guid = billbackContracts.guid;
					// 	this.minMaxEndUsersChargebackContractModelRequest.guid = billbackContracts.guid;
					// 	this.minMaxEndUsersChargebackContractModelRequest.ixcode = billbackContracts.ixcode;
					// }

				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			}
		});
	}

	public updateQuantitiesSoldClick() {
		if (!this.editChargebackContract) {
			this._ToastrService.info('Please save contract first.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (this.itemTabDataList.length === 0) {
			this._ToastrService.info('Without product item will not update quantitie sold.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		this._LoaderService.show();
		const reqFormData = 'usr=' + this._LoginService.loginUser.user + '&token=' + this._LoginService.loginUser.token + '&contractguid=' + this.editChargebackGuid + '&method=updateQuantitiesSold'
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('contractguid', this.editChargebackGuid);
		formData.append('method', 'updateQuantitiesSold');
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
			next: (response) => {
				if (response.flag) {
					this.itemTabDataList = JSON.parse(JSON.stringify(response.data));
					this.itemTabDataSource = this.itemTabDataList;
					this._ToastrService.success('Update Quantities Sold Successfully', 'Saved', { closeButton: true, tapToDismiss: true });
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			}
		});
	}

	public editChargebackContract(event?: any) {
		//let searchParams = [{ 'contractguid': this.editChargebackGuid }];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('mode', ServerMode.HEADER);
		formData.append('entity', ServerEntity.MINMAXENDUSERSCHARGEBACKCONTRACTS_DETAILS);
		formData.append('contractguid', this.editChargebackGuid);
		//formData.append('search', JSON.stringify(searchParams));
		this._LoaderService.show();
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
			next: (response) => {
				if (response.flag) {
					const editChargebackContractDetails = response.data[0];
					if (event && event == 'edit') {
						this.chargebackBasisCondtions();
						if (this.isCustomerChargebackbasis) {
							this.itemTabDataList = editChargebackContractDetails.customers || [];
						} else {
							this.itemTabDataList = editChargebackContractDetails.endusers || [];
							this.itemTabDataSource = editChargebackContractDetails.endusers || [];
						}
					} else {
						this.minMaxEndUsersChargebackContractModelUI.guid = editChargebackContractDetails.guid;
						this.minMaxEndUsersChargebackContractModelUI.ixcode = editChargebackContractDetails.ixcode;
						this.minMaxEndUsersChargebackContractModelUI.contractName = editChargebackContractDetails.contractName;
						this.minMaxEndUsersChargebackContractModelUI.chargebackBasis = editChargebackContractDetails.chargebackBasis || 'endusers';
						this.minMaxEndUsersChargebackContractModelUI.selectCustomerId = editChargebackContractDetails.selectCustomerId;
						this.minMaxEndUsersChargebackContractModelUI.selectCustomer = editChargebackContractDetails.selectCustomer;
						this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListNum = editChargebackContractDetails.selectCustomerPriceListNum;
						this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListName = editChargebackContractDetails.selectCustomerPriceListName;
						this.minMaxEndUsersChargebackContractModelUI.startDate = moment(editChargebackContractDetails.startDate).format('YYYY-MM-DD') || '';
						this.minMaxEndUsersChargebackContractModelUI.endDate = moment(editChargebackContractDetails.endDate).format('YYYY-MM-DD') || '';
						this.minMaxEndUsersChargebackContractModelUI.month_filter = moment(editChargebackContractDetails.month_filter).format('YYYY-MM-DD') || '';
						this.minMaxEndUsersChargebackContractModelUI.billBackType = editChargebackContractDetails.billBackType;
						this.minMaxEndUsersChargebackContractModelUI.is_active = editChargebackContractDetails.is_active;
						if (this.isEnableDistributionChannels) {
							this.distributionChannelsModel.arrSelectedDistributionChannelsList = [];
							if (editChargebackContractDetails.hasOwnProperty('distribution_channels')) {
								this.distributionChannelsModel.arrSelectedDistributionChannelsList.push(editChargebackContractDetails.distribution_channels);
							}
						}

						this.chargebackBasisCondtions();
						if (this.isCustomerChargebackbasis) {
							this.itemTabDataList = editChargebackContractDetails.customers || [];
						} else {
							this.itemTabDataList = editChargebackContractDetails.endusers || [];
							this.itemTabDataSource = editChargebackContractDetails.endusers || [];
						}
						this.chargeBackDocumentsTabDatalist = editChargebackContractDetails.chargeDocuments || [];
						this.customerGridBoxValue = [editChargebackContractDetails.selectCustomerId];
						this.pricelistGridBoxValue = [editChargebackContractDetails.selectCustomerPriceListNum];
						this.minMaxEndUsersChargebackContractModelUI.pricelist_modified_date = editChargebackContractDetails.pricelist_modified_date;
						this.minMaxEndUsersChargebackContractModelUI.pricelist_modified_user_action = editChargebackContractDetails.pricelist_modified_user_action;
					}
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
				// if (this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListNum) {
				// 	this.fetchedListOfPrices(this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListNum, true);
				// }
				this.selectedTabVal = 'items';
				this.loadCondtionsForHTML();
				this._LoaderService.hide();
			}, error: (error) => {
				// this.selectedTabVal = 'items';
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			}
		});
	}

	// Method used to Fetched Price list
	private fetchedListOfPrices(pricelistnum: string, isCheckListPrice: boolean, callFor?: string) {
		this.listOfPrices = [];
		const searchArr = [];
		searchArr.push({ pricelistno: pricelistnum });
		const viewFields = ['pricelistno', 'pricelistname', 'items', 'modified_date'];
		const formdata = new FormData();
		formdata.append('usr', this._LoginService.loginUser.user);
		formdata.append('token', this._LoginService.loginUser.token);
		formdata.append('method', 'getEntityData');
		formdata.append('is_dropdown', '1');
		formdata.append('entity', 'pricelists');
		formdata.append('search', JSON.stringify(searchArr));
		formdata.append('view_fields', JSON.stringify(viewFields));
		if (this.getPriceLstSbsn) {
			this.getPriceLstSbsn.unsubscribe();
		}
		this.getPriceLstSbsn = this._RestApiService.doDataOutReqFormData(formdata).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						this.listOfPrices = response.data || [];
						if (this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListNum && (!callFor)) {
							try {
								this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListName = response.data[0].pricelistname;
							} catch (e) {
								this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListName = '';
							}
						}
						// if (isCheckListPrice) {
						// 	if (this.editChargebackGuid) {
						// 		if (this.productSearchItemType === 'price') {
						// 			this.checkDifferentCustomerListPrice();
						// 		}
						// 	}
						// }

						// chargeback basis as customer add price list in item list
						if (this.minMaxEndUsersChargebackContractModelUI.selectCustomerId && this.listOfPrices.length > 0 && this.isCustomerChargebackbasis && (callFor && callFor == 'customer')) {
							const objPricelist = {
								pricelistno: pricelistnum,
								pricelistname: this.listOfPrices[0].pricelistname
							}
							this.setCusomerPriceList(objPricelist);
						}
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private setCusomerPriceList(objPricelist) {
		if (objPricelist) {
			let selectedCustPriceList: any[] = [];
			selectedCustPriceList.push(objPricelist);
			this.childMmeuccCustomersPricelistTabComponent.addProductAgreementTabDetails(selectedCustPriceList);
		}
	}
	// Method used to Fetched New Price list
	private fetchedNewListOfPrices(pricelistnum: string) {
		this.listOfPrices = [];
		const searchArr = [];
		searchArr.push({ pricelistno: pricelistnum });
		const viewFields = ['pricelistno', 'pricelistname', 'items', 'modified_date'];
		const formdata = new FormData();
		formdata.append('usr', this._LoginService.loginUser.user);
		formdata.append('token', this._LoginService.loginUser.token);
		formdata.append('method', 'getEntityData');
		formdata.append('is_dropdown', '1');
		formdata.append('entity', 'pricelists');
		formdata.append('search', JSON.stringify(searchArr));
		formdata.append('view_fields', JSON.stringify(viewFields));
		if (this.getPriceLstSbsn) {
			this.getPriceLstSbsn.unsubscribe();
		}
		this.getPriceLstSbsn = this._RestApiService.doDataOutReqFormData(formdata).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						this.listOfPrices = response.data || [];
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	private checkDifferentCustomerListPrice() {
		this.isCustomerPriceListChanged = false;
		this.selectCustomerPriceList = '';
		this.compareSelectedCustomerPriceList()
		this.getSelectedCustomerPriceListNum();
	}

	// Method used to check customer pricelist number is changed or not
	private compareSelectedCustomerPriceList() {
		this._GlobalStateService.unsubscribe('GET_SELECT_CUSTOMER_PRICE_LIST_NUMBER');
		this._GlobalStateService.subscribe('GET_SELECT_CUSTOMER_PRICE_LIST_NUMBER', response => {
			const selectCustomerDetails = response.selectCustomerDetails;
			if (selectCustomerDetails) {
				this.selectCustomerPriceList = selectCustomerDetails.pricelistnum;
			}
			const priceListNum = this.getCustomerPriceListNum();
			if (priceListNum !== this.selectCustomerPriceList) {
				this.fetchedNewListOfPrices(this.selectCustomerPriceList);
				this.isCustomerPriceListChanged = true;
			}
			this.checkDifferentListPrice();
		});
	}
	// Method used to update listPrice if Customer price list updated.
	private checkDifferentListPrice() {
		let pricelist_modified_date;
		try {
			pricelist_modified_date = this.listOfPrices[0].modified_date;
		} catch (e) {
			pricelist_modified_date = null;
		}
		if (!this.isCustomerPriceListChanged) {
			if (new Date(this.minMaxEndUsersChargebackContractModelUI.pricelist_modified_date) <= new Date(pricelist_modified_date)) {
				if (this.minMaxEndUsersChargebackContractModelUI.pricelist_modified_user_action === 'ignored') {
					return false;
				}
			}
		}
		const itemGuidList = [];
		this.itemTabDataList.forEach(enduser => {
			if (enduser.hasOwnProperty('items')) {
				enduser.items.forEach(item => {
					itemGuidList.push(item.item_guid);
				});
			}
		});
		if (itemGuidList.length <= 0) {
			return false;
		}

		// this.doCompareListPriceWithUomPrice();
		this.fetchedItemDetailsToCompareListPriceByGuid(itemGuidList);
	}

	// Method used to compare listprice with updated customer pricelist
	private doCompareListPriceWithUomPrice() {
		this._GlobalStateService.unsubscribe('GET_COMPARED_ITEM_DETAILS_PRICELIST_ITMGRPTAB_EVENT');
		this._GlobalStateService.subscribe('GET_COMPARED_ITEM_DETAILS_PRICELIST_ITMGRPTAB_EVENT', response => {
			const itemDetailsList = response.itemDetailsList;
			const priceListNum = this.getCustomerPriceListNum();
			let isShowConfirmDialog = false;

			for (let i = 0; i < this.itemTabDataList.length; i++) {
				const item = this.itemTabDataList[i];
				isShowConfirmDialog = false;
				const itemDetailsIndex = itemDetailsList.findIndex(itmDtl => itmDtl.erp_primary_key === item.erp_primary_key);
				if (itemDetailsIndex !== -1) {
					const itemDetailsOrigin = itemDetailsList[itemDetailsIndex];
					const listOfSpecificUoms = this._BillbackService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, itemDetailsOrigin, priceListNum);
					const uomIndex = listOfSpecificUoms.findIndex(uom => uom.code === item.selectedUom);
					if (uomIndex !== -1) {
						const newListPrice = +listOfSpecificUoms[uomIndex].price;
						if (newListPrice !== +item.listPrice) {
							isShowConfirmDialog = true;
							break;
						}
					}
				}
			}
			// once user can change selected customer pricelist
			if (this.isCustomerPriceListChanged) {
				isShowConfirmDialog = true;
			}
			if (isShowConfirmDialog) {
				let pricelistname = '';
				try {
					pricelistname = this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListName || '';
				} catch (e) {
					pricelistname = '';
				}
				this._ConfirmationDialogService.setDialogConfig({
					popupTitle: "Price List Update!",
					popupMessage: 'Customer price list \'' + pricelistname + '\' has been modified, do you want to update contract price list? ',
					popupOkBtnText: "Yes",
					popupCancelBtnText: "Cancel",
					popupInfoBoxClass: "confirmationIcon"
				});
				this._ConfirmationDialogService.confirmDialog().afterClosed().subscribe(result => {
					if (result) {
						if (this.isCustomerPriceListChanged) {
							if (this.listOfPrices && this.listOfPrices.length > 0) {
								this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListName = this.listOfPrices[0].pricelistname;
							}
							this._GlobalStateService.notifyDataChangedDuplicate('ADD_BULKITEMS_TO_ITMGRP_TAB_LIST_EVENT_FOR_NEW_PRICE_LIST', { itemDetailsList: itemDetailsList, itemTabDataList: this.itemTabDataList });
						}
						else {
							for (let i = 0; i < this.itemTabDataList.length; i++) {
								const item = this.itemTabDataList[i];
								const itemDetailsIndex = itemDetailsList.findIndex(itmDtl => itmDtl.erp_primary_key === item.erp_primary_key);
								const itemDetailsOrigin = itemDetailsList[itemDetailsIndex];
								const listOfSpecificUoms = this._BillbackService.getlistOfUomByItemDetails(this.listOfUoms, this.listOfUomGroups, itemDetailsOrigin, priceListNum);
								item.uomList.forEach(storedUom => {
									const uomIndex = listOfSpecificUoms.findIndex(uom => uom.code.toString().toLowerCase() === storedUom.code.toString().toLowerCase());
									if (uomIndex !== -1) {
										if (+storedUom.price !== +listOfSpecificUoms[uomIndex].price) {
											storedUom.price = +listOfSpecificUoms[uomIndex].price;
										}
									}
								});
								const uomIndex = item.uomList.findIndex(uom => uom.code.toString().toLowerCase() === item.selectedUom.toString().toLowerCase());
								if (uomIndex !== -1) {
									const newPrice = +item.uomList[uomIndex].price || 0;
									if (newPrice !== +item.listPrice) {
										item.listPrice = newPrice;
										// check & fixed chargeback basis final price from application config
										if (this._LoginService.loginUser.account_detail.app_settings.chargeback_price_basis !== "final_price") {
											item.final_amount = newPrice - (+item.chargebackAmount || 0);
										} else {
											item.chargebackAmount = newPrice - (+item.final_amount || 0);
										}
									}
								}

							}
						}
						if (this.editChargebackGuid) {
							this.minMaxEndUsersChargebackContractModelUI.pricelist_modified_user_action = 'agreed';
							if (this.listOfPrices.length > 0) {
								this.minMaxEndUsersChargebackContractModelUI.pricelist_modified_date = this.listOfPrices[0].modified_date;
							}
							setTimeout(() => {
								this.doSaveMinMaxEndUsersChargebackContract();
							}, 700);
						}
					} else {
						if (this.editChargebackGuid) {
							this.minMaxEndUsersChargebackContractModelUI.pricelist_modified_user_action = 'ignored';
							setTimeout(() => {
								this.doSaveMinMaxEndUsersChargebackContract();
							}, 700);
						}
					}
				});
			}
		});
	}

	// Method used to Fetched Price list for selected customer
	private getSelectedCustomerPriceListNum() {

		let selectCustomerDetails = [];
		const searchArr = [];
		searchArr.push({ code: this.minMaxEndUsersChargebackContractModelUI.selectCustomerId });
		const viewFields = ['pricelistnum', 'code', 'name', 'guid'];
		const formdata = new FormData();
		formdata.append('usr', this._LoginService.loginUser.user);
		formdata.append('token', this._LoginService.loginUser.token);
		formdata.append('method', 'getEntityData');
		formdata.append('is_dropdown', '1');
		formdata.append('entity', 'clients');
		formdata.append('search', JSON.stringify(searchArr));
		formdata.append('view_fields', JSON.stringify(viewFields));
		if (this.getCustomerPriceLstSbsn) {
			this.getCustomerPriceLstSbsn.unsubscribe();
		}
		this.getCustomerPriceLstSbsn = this._RestApiService.doDataOutReqFormData(formdata).subscribe({
			next: (response) => {
				if (response) {
					if (response.flag) {
						const customerList = response.data || [];
						if (customerList.length > 0) {
							selectCustomerDetails = customerList[0];
							this._GlobalStateService.notifyDataChangedDuplicate('GET_SELECT_CUSTOMER_PRICE_LIST_NUMBER', { selectCustomerDetails: selectCustomerDetails });
						}
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}
	// Method used to fetched item details to compare listprice with updated customer pricelist
	private fetchedItemDetailsToCompareListPriceByGuid(itemGuidList: string[]) {
		let itemDetailsList = [];
		let priceListNum;
		if (this.selectCustomerPriceList) {
			priceListNum = this.selectCustomerPriceList;
		} else {
			this.getCustomerPriceListNum();
		}
		const view_fields = ['guid', 'erp_primary_key', 'itemname', 'itemsku', 'description', 'baseuom', 'uomgroupentry', 'itemprices'];
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'item_details');
		formData.append('itemGuid', JSON.stringify(itemGuidList));
		formData.append('is_dropdown', 'true');
		formData.append('product_search_from', 'chargebacks');
		formData.append('view_fields', JSON.stringify(view_fields));
		if (priceListNum) {
			formData.append('pricelistnum', priceListNum);
		}
		if (this.getItemDetailsSbsn) {
			this.getItemDetailsSbsn.unsubscribe();
		}
		// this._LoaderService.show();
		this.getItemDetailsSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				// this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						itemDetailsList = response.data || [];
					} else {
						this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
					}
				} else {
					this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
				this._GlobalStateService.notifyDataChangedDuplicate('GET_COMPARED_ITEM_DETAILS_PRICELIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
			}, error: (error) => {
				this._GlobalStateService.notifyDataChangedDuplicate('GET_COMPARED_ITEM_DETAILS_PRICELIST_ITMGRPTAB_EVENT', { itemDetailsList: itemDetailsList });
				// this._LoaderService.hide();
				this._ToastrService.error(MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	// Method used to return selected customer pricelistnum
	private getCustomerPriceListNum() {
		let priceListNum = '';
		try {
			priceListNum = this.minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListNum || '';
		} catch (e) {
			priceListNum = '';
		}
		return priceListNum;
	}

	//#region Call API Download chargeback/enduser chargeback file
	downloadPriceList() {
		if (this.editChargebackGuid) {
			this._LoaderService.show();
			let reqFormData = 'usr=' + this._LoginService.loginUser.user + '&token=' + this._LoginService.loginUser.token + '&contractguid=' + this.editChargebackGuid;
			reqFormData = reqFormData + '&method=downloadChargebackContract'
			this.dataInReqSubscription = this._RestApiService.doDataOutReqDownloadFile((reqFormData)).subscribe({
				next: (response) => {
					this.downloadFile(response);
					this._LoaderService.hide();
				}, error: (error) => {
					this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
					this._LoaderService.hide();
				}
			});
		}
	}
	downloadFile(data: Blob) {
		const blob = new Blob([data], { type: 'application/pdf' });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		const ixcode = this.minMaxEndUsersChargebackContractModelUI.ixcode ? this.minMaxEndUsersChargebackContractModelUI.ixcode : this.minMaxEndUsersChargebackContractModelRequest.ixcode;
		link.download = ixcode + '_' + this.editChargebackGuid;
		link.target = '_blank';
		link.dispatchEvent(new MouseEvent('click'));
	}
	//#endregion
	private doExportToExcel(e) {
		const workbook = new ExcelJS.Workbook();
		let workSheet, componentObj;
		if (this.childEuccItemsTabComponent && this.childEuccItemsTabComponent.itemTabDataGridContainer) {
			workSheet = workbook.addWorksheet('Items');
			componentObj = this.childEuccItemsTabComponent.itemTabDataGridContainer.instance;
		}
		if (this.childEuccChargebackDocumentsTabComponent && this.childEuccChargebackDocumentsTabComponent.chargeDocumentsTabDataGridContainer) {
			workSheet = workbook.addWorksheet('Chargeback Documents');
			componentObj = this.childEuccChargebackDocumentsTabComponent.chargeDocumentsTabDataGridContainer.instance;
		}
		if (componentObj && componentObj.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}

		this._BillbackService.exportBillbackChargebackExcel(workbook, this.minMaxEndUsersChargebackContractModelUI, workSheet, componentObj);
	}

	//#region condition method for HTML view
	loadCondtionsForHTML() {
		this.isCondOfViewCustPricelistTab = ((this.selectedTabVal === this.tabItemsList[0].key) && (this.productSearchItemType === 'price') && (this.isCustomerChargebackbasis));
		this.isCondOfViewEndUsersPricelistTab = ((this.selectedTabVal === this.tabItemsList[0].key) && (this.productSearchItemType === 'price') && (!this.isCustomerChargebackbasis));
	}
	//#endregion

	// Load Distribution channel
	public loadDistributionChannel() {
		if (this.isEnableDistributionChannels) {
			this.distributionChannelsModel.arrSelectedDistributionChannelsList = [];
			this.distributionChannelsModel.callFromView = DistributionChannelsCallFromViewEnum.MIN_MAX_CHARGEBACK_CONTRACT;
			this.distributionChannelsModel.showSelectionControls = false;
			this.distributionChannelsModel.applyValueMode = 'instantly';
			this.distributionChannelsModel.selectionMode = 'single';
		}
	}
}

