<dx-popup [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width90:'X'"
	[height]="addEditPopupHeight | heightwidthRatio : 85:'Y'" [showTitle]="true" [dragEnabled]="true"
	[hideOnOutsideClick]="false" [title]="pageTitle" [showCloseButton]="true" [(visible)]="isShowPopup"
	[wrapperAttr]="{class: 'twoPopupUpLoaderIx' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<div class="rightBar" id="mainContent1">
				<div class="rightBlock">
					<div *ngIf="isResponse" class="contentRow cf">
						<div *ngIf="!creditMemoDetails" class="row">
							<div class="cols12 center">No Records Found.</div>
						</div>
						<div *ngIf="creditMemoDetails && creditMemoDetails.guid" class="quoteDesign cf">
							<div class="orderBox">
								<div class="subTitleRow" *ngIf="creditMemoDetails && creditMemoDetails.docnum">
									<!-- AR Credit Memo ID -->
									<h2>
										<span class="lbl">AR Credit Memo </span>
										{{creditMemoDetails.docnum ? '# '+ creditMemoDetails.docnum : ''}}
									</h2>
									<span *ngIf="creditMemoDetails.virtual && isShowVirtualTag"
										class="ix-virtualTag mrgL20 highlight">Virtual</span>
								</div>
								<div class="orderBox row">
									<div class="orderDetail cols4">
										<span class="custId" *ngIf="isShowCustomerID">
											Customer ID
											{{creditMemoDetails?.cardcode}}
										</span>
										<span *ngIf="creditMemoDetails.source_docnum" class="custId">
											<!-- creditMemoDetails.virtual && creditMemoDetails.source_docnum -->
											Source Invoice <span (click)="doShowOriginalInvoiceDetails()"
												class="highlight pointerMark">
												#{{creditMemoDetails.source_docnum}}</span>
										</span>
										<h3 class="custNm">
											<span class="contTx lbl mrgB0">Customer : </span>
											{{creditMemoDetails?.cardname}}
										</h3>
										<div class="contDtl">
											<span class="contTx icoDate" *ngIf="creditMemoDetails?.docdate">
												<span class="lbl">Credit Memo Date:
												</span>
												{{ creditMemoDetails?.docdate || '' | customDateFormatPipe:'dateonly'}}
											</span>
											<span class="contTx icoDate" *ngIf="creditMemoDetails?.docduedate">
												<span class="lbl">Due Date: </span>
												{{ creditMemoDetails?.docduedate || '' | customDateFormatPipe:'dateonly'}}
											</span>
										</div>

										<div *ngIf="isEnableDistributionChannels && arInvoiceDetails.distribution_channels"
											class="contDtl">
											<span class="contTx icoDate">
												<span class="lbl">Distribution Channel: </span>
												{{arInvoiceDetails.distribution_channels || ''
												|filterFromList:listOfDistributionChannels : 'code' : 'code'}} -
												{{arInvoiceDetails.distribution_channels || '' |
												filterFromList:listOfDistributionChannels : 'code' : 'description'}}
											</span>
										</div>

										<div *ngIf="creditMemoDetails.ponumber || creditMemoDetails.shipmethod"
											class="poShipping">
											<div class="row">
												<div *ngIf="creditMemoDetails.ponumber" class="cols6">
													<span>PO Number</span>{{creditMemoDetails.ponumber}}
												</div>
												<div *ngIf="creditMemoDetails.shipmethod" class="cols6">
													<span>Shipping Method</span>
													{{creditMemoDetails.shipmethod}}
												</div>
											</div>
										</div>
									</div>

									<div class="orderAddress cols6 padL0"
										*ngIf="creditMemoDetails && (creditMemoDetails.address || creditMemoDetails.address2)">
										<div *ngIf="creditMemoDetails && creditMemoDetails.address" class="cols6 addressBox"
											[ngClass]="{'singleBlock':!(creditMemoDetails.address2)}">
											<h3 class="custNm">Billing Address</h3>
											<span class="contTx" [innerHTML]="creditMemoDetails.address"></span>
										</div>
										<div *ngIf="creditMemoDetails && creditMemoDetails.address2" class="cols6 addressBox"
											[ngClass]="{'singleBlock':!(creditMemoDetails.address)}">
											<h3 class="custNm">Shipping Address</h3>
											<span class="contTx" [innerHTML]="creditMemoDetails.address2">
											</span>
										</div>
									</div>

									<div class="orderStatus odStatus cols2">
										<ul class="statusBxLnremove">
											<li>
												<span class="stLbl">Status:</span>
												<span class="inStatusLabel orangeInvoice alignRight"
													*ngIf="!isQBErpUser && creditMemoDetails?.documentstatus =='bost_Open'">Open</span>
												<span class="inStatusLabel greenInvoice alignRight"
													*ngIf="!isQBErpUser && creditMemoDetails?.documentstatus == 'bost_Close'">Closed</span>
												<span class="inStatusLabel orangeInvoice alignRight"
													*ngIf="creditMemoDetails?.documentstatus =='draft'">Draft</span>
												<span class="inStatusLabel greenInvoice alignRight" *ngIf="isQBErpUser && (creditMemoDetails?.documentstatus =='bost_Close' || creditMemoDetails?.documentstatus =='bost_Open'
													)">Closed</span>
												<span class="inStatusLabel redInvoice alignRight"
													*ngIf="creditMemoDetails?.documentstatus =='bost_Cancel'">Canceled</span>
												<span class="inStatusLabel redInvoice alignRight"
													*ngIf="creditMemoDetails?.documentstatus =='deleted'">Deleted</span>
											</li>
										</ul>
									</div>

								</div>
							</div>

							<div class=" padB10 mrgB0 cf"
								*ngIf="creditMemoDetails && creditMemoDetails.documentlines && creditMemoDetails.documentlines.length>0">
								<div class="subTitleRow">
									<h2>Document Listing</h2>
								</div>

								<div *ngIf="isShowDataGrid">
									<div class="mrgB0 mrgT10 cf">
										<dx-data-grid #itemsListOriginDatagridRef [dataSource]="creditMemoDetails.documentlines"
											[wordWrapEnabled]="true" keyExpr="linenum" [showBorders]="true"
											[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
											[columnAutoWidth]="true" [width]="'100%'"
											(onRowPrepared)="doRowPreparedDocLine($event)"
											[masterDetail]="{ enabled:isEnabledMasterDetails, template: 'masterDetail','autoExpandAll': false }"
											class="hoverClr hideFilterPanelInx minHeightDataGrid150">
											<dxo-scrolling mode="virtual" [scrollByThumb]="true" [showScrollbar]="'always'"
												[useNative]="false"></dxo-scrolling>


											<dxi-column dataField="itemname" dataType="string" caption="Item"
												cellTemplate="cellTemplateItem">
												<div *dxTemplate="let element of 'cellTemplateItem'">
													{{element.data.itemname || ''}}
												</div>
											</dxi-column>
											<dxi-column dataField="description" dataType="string" caption="Description"
												cellTemplate="cellTemplateDescription">
												<div *dxTemplate="let element of 'cellTemplateDescription'">
													{{element.data.description || ''}}
												</div>
											</dxi-column>

											<dxi-column dataField="cardcode" dataType="boolean" [visible]="isShowCardcodeColumn"
												caption="Customer Code" cellTemplate="cellTemplateCardcode" [minWidth]="80"
												[width]="'10%'">
												<div *dxTemplate="let element of 'cellTemplateCardcode'">
													{{element.data.cardcode || ''}}
												</div>
											</dxi-column>

											<dxi-column dataField="price" dataType="number"
												[caption]="'Rate '+ globalCurrencySymbol">
												<dxo-format type="currency" [precision]="decimalPointForCurrency">
												</dxo-format>
												<div *dxTemplate="let element of 'cellTemplateRate'">
													{{element.data.price || 0 | customCurrencyPipe}}
												</div>
											</dxi-column>
											<dxi-column dataField="quantity" dataType="number" caption="Quantity"
												cellTemplate="cellTemplateQuantity">
												<div *dxTemplate="let element of 'cellTemplateQuantity'">
													{{element.data.quantity}}
												</div>
											</dxi-column>
											<dxi-column dataField="linetotal" dataType="number" caption="Total">
												<dxo-format type="currency" [precision]="decimalPointForCurrency">
												</dxo-format>
												<div *dxTemplate="let element of 'cellTemplateTotal'">
													{{element.data.linetotal || 0 | customCurrencyPipe}}
												</div>
											</dxi-column>
											<dxi-column caption="View Document" cellTemplate="cellTemplateViewDoc"
												*ngIf="isShowViewDocuments">
												<div *dxTemplate="let element of 'cellTemplateViewDoc'">
													<a *ngIf="creditMemoDetails.chargebackDocGuid" class="centeredIcon viewIconBtn"
														(click)="viewChargeBackDocumentDetails()"><span
															class="sprite viewIcon "></span></a>
												</div>
											</dxi-column>

										</dx-data-grid>
									</div>
								</div>

								<div class="cols12 paymentBtmRow">
									<div class="row">
										<diV class="totalRowCal withAddBtn noActClm noBatchClm">
											<div class="alignRight mrgT10">
												<div class="cols12">
													<div class="paySubTotal totalWithAct">
														Subtotal :
														<span class="txtRight">
															{{creditMemoDetails.doctotalsys || 0 | customCurrencyPipe}}
														</span>
													</div>
												</div>
												<div *ngIf="creditMemoDetails.totaldiscount && creditMemoDetails.totaldiscount > 0"
													class="cols12">
													<div class="paySubTotal totalWithAct">
														Discount :
														<span class="txtRight">
															{{creditMemoDetails.totaldiscount || 0 | customCurrencyPipe }}
														</span>
													</div>
												</div>
												<div
													*ngIf="creditMemoDetails?.totalmiscamount && creditMemoDetails.totalmiscamount > 0"
													class="cols12">
													<div class="paySubTotal totalWithAct">
														Miscellaneous Charge:
														<span class="txtRight">
															{{creditMemoDetails.totalmiscamount || 0 | customCurrencyPipe }}
														</span>
													</div>
												</div>
												<div
													*ngIf="creditMemoDetails.shippingcharges && creditMemoDetails.shippingcharges > 0"
													class="cols12">
													<div class="paySubTotal totalWithAct">
														Frieght Charge:
														<span class="txtRight">
															{{creditMemoDetails.shippingcharges || 0 | customCurrencyPipe }}
														</span>
													</div>
												</div>
												<div class="cols12">
													<div class="paySubTotal payFinalTotal">
														Total :
														<span class="txtRight">
															{{creditMemoDetails.doctotal || 0 | customCurrencyPipe }}
														</span>
													</div>
												</div>
											</div>
										</diV>
										<div class="cols12 mrgT10 padL30">
											<div class="dx-field feildCol mrgT10 mrgB20 remarksinputDisable">
												<div class="dx-field-label">Remarks</div>
												<div class="dx-field-value" style="width: 100%;">
													<dx-text-area [height]="60" [maxLength]="400"
														[(value)]="creditMemoDetails.journalmemo" [disabled]="true">
													</dx-text-area>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</dx-scroll-view>
	</div>
</dx-popup>