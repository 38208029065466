<dx-popup [width]="addEditPopupWidth | heightwidthRatio : _PopupHeightWidth.Width90:'X'"
	[height]="addEditPopupHeight | heightwidthRatio : 85:'Y'" [showTitle]="true" [dragEnabled]="true"
	[hideOnOutsideClick]="false" [title]="pageTitle" [showCloseButton]="true" [(visible)]="isShowPopup"
	[wrapperAttr]="{class: 'twoPopupUpLoaderIx' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<div class="rightBar" id="mainContent1">
				<div class="rightBlock">
					<div *ngIf="isResponse" class="contentRow cf">
						<div *ngIf="!invoiceDetails" class="row">
							<div class="cols12 center">No Records Found.</div>
						</div>
						<div *ngIf="invoiceDetails && invoiceDetails.guid" class="quoteDesign cf">
							<div class="contBtn titleBtnCol">
							</div>

							<div class="subTitleRow" *ngIf="invoiceDetails.docnum">
								<!-- Invoice ID -->
								<h2>
									<span class="lbl">Source Invoice # </span>
									{{invoiceDetails.docnum || ''}}
								</h2>
								<span *ngIf="invoiceDetails.documentstatus =='bost_Open'"
									class="inStatusLabel orangeInvoice mrgL20">Open</span>
								<span *ngIf="invoiceDetails.documentstatus =='bost_Close'"
									class="inStatusLabel greenInvoice mrgL20">Closed</span>
								<span *ngIf="invoiceDetails.documentstatus =='bost_Cancel'"
									class="inStatusLabel redInvoice mrgL20">Canceled</span>
								<span *ngIf="invoiceDetails.documentstatus =='deleted'"
									class="inStatusLabel redInvoice mrg210">Deleted</span>
							</div>
							<div class="orderBox row">
								<div class="orderDetail cols4">
									<!-- <div class="custImg">
										<span class="icon-default-allcompany icon-set1"></span>
									</div> -->

									<div class="custDetail">
										<span class="custId" *ngIf="!isQBErpUser">
											<!-- Customer ID -->
											<span class="lbl">Customer ID #</span>
											<span
												*ngIf="!invoiceDetails.human_readable_account_code">{{invoiceDetails.cardcode}}</span>
											<span
												*ngIf="invoiceDetails.human_readable_account_code">{{invoiceDetails.human_readable_account_code}}</span>
										</span>

										<h3 class=" custNm">
											<span class="contTx lbl mrgB0 mrgR0">Customer: </span>
											{{invoiceDetails.cardname}}
										</h3>
										<div *ngIf="invoiceDetails.salespersoncode && invoiceDetails.salesreps">
											<!-- <h4 class="graySubHead">Contact Persons</h4> -->
											<div class="contDtl">
												<span class="contTx">
													<span class="lbl">Sales Person: </span>
													<strong *ngIf="!isMultipleSalesReps">
														{{invoiceDetails.salespersoncode || '' | multifilterFromList: listOfSalesPpl:
														'code':'firstname,lastname'}}
													</strong>

													<strong
														*ngIf="isMultipleSalesReps && invoiceDetails.salesreps && invoiceDetails.salesreps[0]">
														{{invoiceDetails.salesreps[0].salesrep || '' | multifilterFromList:
														listOfSalesPpl:
														'code':'firstname,lastname'}}
													</strong>
													<span
														*ngIf="invoiceDetails.salesreps && invoiceDetails.salesreps[0] && invoiceDetails.salesreps.length >= 2"
														id="salesPersonToolTip_{{invoiceDetails.guid}}" class="highlight pointerMark">
														+ {{invoiceDetails.salesreps.length-1}}
														{{ invoiceDetails.salesreps.length === 2 ? 'Other' :
														invoiceDetails.salesreps.length >= 3 ?
														'Others' : ''}}
													</span>
													<span *ngIf="invoiceDetails.contactpersondata.position">
														{{invoiceDetails.contactpersondata.position}}
													</span>
													<dx-tooltip target="#salesPersonToolTip_{{invoiceDetails.guid}}"
														showEvent="dxhoverstart" hideEvent="dxhoverend" position="right">
														<div *dxTemplate="let d = d; of: 'content'">
															<div class="tooltip-wrapper">
																<div class="greenTipout">
																	<ol class="tipList">
																		<span
																			*ngFor="let salesRep of invoiceDetails.salesreps; let p = index;">
																			<li
																				*ngIf="salesRep.salesrep || '' | multifilterFromList:listOfSalesPpl : 'code' : 'firstname,lastname'">
																				<a>
																					<span>
																						{{salesRep.salesrep || '' |
																						multifilterFromList:listOfSalesPpl
																						:
																						'code' : 'firstname,lastname'}} ({{(salesRep.percent
																						|| 0 |
																						number:decimalPointPercentageFormat) + '%' }})
																					</span>
																				</a>
																			</li>
																		</span>
																	</ol>
																</div>
															</div>
														</div>
													</dx-tooltip>
												</span>
												<span class="contTx icoCall" *ngIf="invoiceDetails.contactpersondata.phone1">
													<span class="icon-call-1"></span>
													<a>
														{{invoiceDetails.contactpersondata.phone1}}
													</a>
												</span>
												<span class="contTx icoMail" *ngIf="invoiceDetails.contactpersondata.e_mail">
													<span class="icon-mail"></span>
													<a>
														{{invoiceDetails.contactpersondata.e_mail}}
													</a>
												</span>
												<!-- <span class="contTx">
												<span class="lbl">Shipping Type: </span>[SHIPPING TYPE]
											</span> -->
											</div>
										</div>

										<div class="contDtl" *ngIf="invoiceDetails.docdate || invoiceDetails.docduedate">
											<span class="contTx icoDate" *ngIf="invoiceDetails.docdate">
												<span class="lbl">Invoice Date: </span>
												{{ invoiceDetails.docdate || '' | customDateFormatPipe:'dateonly'}}
											</span>
											<span class=" contTx icoDate" *ngIf="invoiceDetails.docduedate">
												<span class=" lbl">Due Date: </span>
												{{ invoiceDetails.docduedate || '' | customDateFormatPipe:'dateonly'}}
											</span>
											<span class="contTx icoDate" *ngIf="invoiceDetails.doc_close_date">
												<span class="lbl">Date Paid: </span>
												{{ invoiceDetails.doc_close_date || '' | customDateFormatPipe:'dateonly'}}
											</span>
										</div>
										<div *ngIf="isShowEligibleField" class="contDtl">
											<span class="contTx icoDate">
												<span class="lbl">Eligible: </span>
												{{ invoiceDetails.eligible ? 'Yes' : 'No'}}
											</span>
										</div>
										<div *ngIf="isEnableDistributionChannels && salesDetails.distribution_channels"
											class="contDtl">
											<span class="contTx icoDate">
												<span class="lbl">Distribution Channel: </span>
												{{salesDetails.distribution_channels || ''
												|filterFromList:listOfDistributionChannels : 'code' : 'code'}} -
												{{salesDetails.distribution_channels || '' |
												filterFromList:listOfDistributionChannels : 'code' : 'description'}}
											</span>
										</div>
										<span class="contTx icoDate" *ngIf="invoiceDetails?.branch">
											<span class="lbl">Branch: </span>
											{{ invoiceDetails?.branch || ''}}
										</span>
										<div class="contDtl"
											*ngIf="invoiceDetails.billing_start_date || invoiceDetails.billing_end_date">
											<span class="contTx icoDate" *ngIf="invoiceDetails.billing_start_date">
												<span class="lbl">Billing Start Date: </span>
												{{ invoiceDetails.billing_start_date || '' | customDateFormatPipe:'dateonly'}}
											</span>
											<span class="contTx icoDate" *ngIf="invoiceDetails.billing_end_date">
												<span class=" lbl">Billing End Date: </span>
												{{ invoiceDetails.billing_end_date || '' | customDateFormatPipe:'dateonly'}}
											</span>
										</div>
									</div>
									<div *ngIf="invoiceDetails.ponumber || invoiceDetails.shipmethod" class="poShipping">
										<div class="row">
											<div *ngIf="invoiceDetails.ponumber" class="cols6">
												<span>PO Number: </span>{{invoiceDetails.ponumber}}
											</div>
											<div *ngIf="invoiceDetails.shipmethod" class="cols6">
												<span>Shipping Method: </span>
												{{invoiceDetails.shipmethod}}
											</div>
										</div>
									</div>
								</div>

								<div *ngIf="invoiceDetails && (invoiceDetails.address || invoiceDetails.address2)"
									class="orderAddress cols6 detailsPage">
									<div *ngIf="invoiceDetails && invoiceDetails.address" class="cols6 addressBox"
										[ngClass]="{'singleBlock':!(invoiceDetails.address2)}">
										<h3 class="custNm">Billing Address</h3>
										<span class="contTx" [innerHTML]="invoiceDetails.address"></span>
									</div>
									<div *ngIf="invoiceDetails && invoiceDetails.address2" class="cols6 addressBox"
										[ngClass]="{'singleBlock':!(invoiceDetails.address)}">
										<h3 class="custNm">Shipping Address</h3>
										<span class="contTx" [innerHTML]="invoiceDetails.address2">
										</span>
									</div>
								</div>

								<div class="orderStatus odStatus cols2 noBdr mrgT0">
									<ul *ngIf="invoiceDetails.totalIncentivePaid !== 0" class="statusBxLnremove">
										<li>
											<span class="stLbl">Incentives:</span>
											<span class="txtRight inlineBlock alignRight"
												[attr.id]="invoiceDetails.totalIncentivePaid > 0 ? 'incentiveDetails' : null"
												[ngClass]="{'highlight pointerMark':invoiceDetails.totalIncentivePaid > 0}">
												{{invoiceDetails.totalIncentivePaid || 0 | customCurrencyPipe:customCurrencyOption}}
											</span>
											<dx-tooltip target="#incentiveDetails" showEvent="dxhoverstart" hideEvent="dxhoverend"
												position="bottom">
												<div *dxTemplate="let data = data of 'content'">
													Incentive Details
												</div>
											</dx-tooltip>
										</li>
									</ul>
									<ul *ngIf="invoiceDetails.doctotal !== 0" class="statusBxLnremove">
										<li>
											<span class="stLbl">Invoice Total:</span>
											<span class="alignRight">
												{{myMath.abs(invoiceDetails.doctotal) || 0 |
												customCurrencyPipe:customCurrencyOption}}
											</span>
										</li>
									</ul>
									<ul *ngIf="invoiceDetails.cashdiscount" class="statusBxLnremove">
										<li>
											<span class="stLbl">Cash Discount:</span>
											<span class="alignRight">
												{{myMath.abs(invoiceDetails.cashdiscount) || 0 |
												customCurrencyPipe:customCurrencyOption}}
											</span>
										</li>
									</ul>
									<ul *ngIf="invoiceDetails.totalpaidamount" class="statusBxLnremove">
										<li>
											<span class="stLbl">Total Payment:</span>
											<span (click)="doShowPaymentDetails()" id="showTotalPaidAmount" class="alignRight"
												[ngClass]="{'pointerMark highlight': isExistsPayementInfo}">
												{{myMath.abs(invoiceDetails.totalpaidamount) || 0 |
												customCurrencyPipe:customCurrencyOption}}
											</span>
											<dx-tooltip target="#showTotalPaidAmount" showEvent="dxhoverstart"
												hideEvent="dxhoverend" position="bottom">
												<div *dxTemplate="let data = data of 'content'">
													<span *ngIf="!isExistsPayementInfo">Payment information not available</span>
													<span *ngIf="isExistsPayementInfo">View Payment information</span>
												</div>
											</dx-tooltip>
										</li>
									</ul>
								</div>

							</div>
							<div
								*ngIf="isEnableProfitOnDocHeaderFeature && (specificListOfProfitOnDocHeader && specificListOfProfitOnDocHeader.length > 0)"
								class="width100p">
								<div class="cols12 padR0 padL0">
									<div class="externalFieldBgix mrgB10 mrgT10">
										<p class="externalFieldTitleix">External Field</p>
										<p *ngFor="let specificField of specificListOfProfitOnDocHeader;let menuIndex = index;"
											class="externalFieldDescriptionix">
											<span
												class="externalFieldLabelix">{{specificField.custom_label}}:</span>{{specificField.custom_display_value}}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="invoiceDetails && invoiceDetails.guid" class="seprator mrgB20 mrgT10"></div>
						<div class=" padB10 mrgB0 cf"
							*ngIf="invoiceDetails && invoiceDetails.documentlines && invoiceDetails.documentlines.length>0">
							<div class="subTitleRow">
								<div class="right secondaryTitle">
									<div *ngIf="!isQBOnlineErpUser" class="noteBx mrg0">
										<span>
											<strong>Customer Price List :</strong>
										</span>
										<span *ngIf="listOfPrices && listOfPrices.length > 0">
											{{listOfPrices[0].pricelistname || ''}}
										</span>
										<span *ngIf="!listOfPrices || (listOfPrices && listOfPrices.length <= 0)">
											<strong> &mdash; </strong>
										</span>
									</div>
								</div>
								<h2>List of Items</h2>
							</div>

							<div *ngIf="isShowDataGrid">
								<div class="mrgB0 mrgT10 cf">
									<dx-data-grid #itemsListOriginDatagridRef [dataSource]="invoiceDetails.documentlines"
										[wordWrapEnabled]="true" keyExpr="linenum" [showBorders]="true" [showColumnLines]="true"
										[showRowLines]="false" [allowColumnResizing]="true" [columnAutoWidth]="true"
										[width]="'100%'" (onRowPrepared)="doRowPreparedDocLine($event)"
										[masterDetail]="{ enabled:isEnabledMasterDetails, template: 'masterDetail','autoExpandAll': false }"
										class="hoverClr hideFilterPanelInx minHeightDataGrid150">
										<dxo-scrolling mode="virtual" [scrollByThumb]="true" [showScrollbar]="'always'"
											[useNative]="false"></dxo-scrolling>

										<dxi-column dataField="itemsku" dataType="string" caption="SKU"
											[visible]="isQBOnlineErpUser" [width]="'9%'" [minWidth]="90" [allowSorting]="false"
											cellTemplate="cellTemplateItemSku">
											<div *dxTemplate="let element of 'cellTemplateItemSku'">
												{{element.data.itemsku || ''}}
											</div>
										</dxi-column>

										<dxi-column dataField="itemname" dataType="string" caption="Item" [minWidth]="100"
											[width]="'12%'" cellTemplate="cellTemplateItemName">
											<div *dxTemplate="let element of 'cellTemplateItemName'">
												{{element.data.itemname || ''}}
											</div>
										</dxi-column>

										<dxi-column dataField="description" dataType="string" caption="Description"
											[allowSorting]="false" cellTemplate="cellTemplateDescription" [minWidth]="120">
											<div *dxTemplate="let element of 'cellTemplateDescription'">
												{{element.data.description || ''}}
											</div>
										</dxi-column>

										<dxi-column dataField="class" [visible]="isShowClassesColumn" caption="Classes"
											dataType="object" cellTemplate="cellTemplateClasses" [minWidth]="120" [width]="'7%'"
											[allowSorting]="false">
											<div *dxTemplate="let element of 'cellTemplateClasses'">
												{{element.data.class && element.data.class.name ? element.data.class.name : '' }}
											</div>
										</dxi-column>

										<dxi-column dataField="costperitem" [visible]="isShowClassesColumn" caption="Cost"
											dataType="number" [minWidth]="120" [width]="'7%'" [allowSorting]="false">
											<dxo-format type="currency" [precision]="decimalPointForCurrency">
											</dxo-format>
											<div *dxTemplate="let element of 'cellTemplateCostPerItem'">
												{{element.data.costperitem || 0 | customCurrencyPipe:customCurrencyOption}}
											</div>
										</dxi-column>

										<dxi-column dataField="assigned_date" [visible]="false" caption="Cost Assignment Date"
											dataType="date" format="yyyy-MM-dd" cellTemplate="cellTemplateAssignedDate"
											[minWidth]="120" [width]="'11%'" [allowSorting]="false">
											<div *dxTemplate="let element of 'cellTemplateAssignedDate'">
												{{element.data.assigned_date ? element.data.assigned_date : '' }}
											</div>
										</dxi-column>

										<dxi-column dataField="billing_start_date"
											[visible]="isEnableAmortisePaymentsFeature && isItemListLevel"
											caption="Billing Start Date" dataType="date" format="yyyy-MM-dd"
											cellTemplate="cellTemplateBillingStartDate" [minWidth]="120" [width]="'11%'"
											[allowSorting]="false">
											<div *dxTemplate="let element of 'cellTemplateBillingStartDate'">
												{{element.data.billing_start_date ? element.data.billing_start_date : '' }}
											</div>
										</dxi-column>

										<dxi-column dataField="billing_end_date"
											[visible]="isEnableAmortisePaymentsFeature && isItemListLevel"
											caption="Billing End Date" dataType="date" format="yyyy-MM-dd"
											cellTemplate="cellTemplateBillingEndDate" [minWidth]="120" [width]="'11%'"
											[allowSorting]="false">
											<div *dxTemplate="let element of 'cellTemplateBillingEndDate'">
												{{element.data.billing_end_date ? element.data.billing_end_date : '' }}
											</div>
										</dxi-column>

										<dxi-column dataField="costassignment_id" dataType="string" [visible]="false"
											caption="Cost Assignment ID" cellTemplate="cellTemplateCostAssignmentId"
											[minWidth]="150" [width]="'8%'" [allowSorting]="false">
											<div *dxTemplate="let element of 'cellTemplateCostAssignmentId'">
												{{element.data.costassignment_id ? element.data.costassignment_id : '' }}
											</div>
										</dxi-column>

										<dxi-column dataField="cardcode" dataType="boolean" [visible]="isShowCardcodeColumn"
											caption="Customer Code" cellTemplate="cellTemplateCardcode" [minWidth]="80"
											[width]="'10%'">
											<div *dxTemplate="let element of 'cellTemplateCardcode'">
												{{element.data.cardcode || ''}}
											</div>
										</dxi-column>

										<dxi-column dataField="eligible" dataType="boolean" [visible]="isShowEligibleColumn"
											caption="Eligible" cellTemplate="cellTemplateEligible" [minWidth]="70" [width]="'4%'">
											<div *dxTemplate="let element of 'cellTemplateEligible'">
												{{element.data.eligible ? 'Yes' : 'No' }}
											</div>
										</dxi-column>

										<dxi-column dataField="salesreps" dataType="object" [visible]="isMultipleSalesReps"
											caption="Sales Person(s)" cellTemplate="cellTemplateSalesreps" [minWidth]="90"
											[width]="'10%'">
											<div *dxTemplate="let element of 'cellTemplateSalesreps'">
												<strong *ngIf="element.data.salesreps && element.data.salesreps[0]">
													{{element.data.salesreps[0].salesrep || '' | multifilterFromList: listOfSalesPpl:
													'code':'firstname,lastname'}}
												</strong>
												<span
													*ngIf="element.data.salesreps && element.data.salesreps[0] && element.data.salesreps.length >= 2"
													id="salesPersonToolTip_{{element.data.linenum}}" class="highlight pointerMark">
													+ {{element.data.salesreps.length-1}}
													{{ element.data.salesreps.length === 2 ? 'Other' : element.data.salesreps.length
													>= 3 ?
													'Others' : ''}}
												</span>
												<dx-tooltip target="#salesPersonToolTip_{{element.data.linenum}}"
													showEvent="dxhoverstart" hideEvent="dxhoverend" position="right">
													<div *dxTemplate="let d = d; of: 'content'">
														<div class="tooltip-wrapper">
															<div class="greenTipout">
																<ol class="tipList">
																	<span
																		*ngFor="let salesRep of element.data.salesreps; let p = index;">
																		<li
																			*ngIf="salesRep.salesrep || '' | multifilterFromList:listOfSalesPpl : 'code' : 'firstname,lastname'">
																			<a>
																				<span>
																					{{salesRep.salesrep || '' |
																					multifilterFromList:listOfSalesPpl :
																					'code' : 'firstname,lastname'}} ({{(salesRep.percent || 0
																					|
																					number:decimalPointPercentageFormat) + '%' }})
																				</span>
																			</a>
																		</li>
																	</span>
																</ol>
															</div>
														</div>
													</div>
												</dx-tooltip>
											</div>
										</dxi-column>

										<dxi-column dataField="uomcode" dataType="string" caption="UOM"
											cellTemplate="cellTemplateUomCode" [minWidth]="80" [width]="'5%'"
											[allowSorting]="false">
											<dxo-lookup [dataSource]="uomList" valueExpr="code">
											</dxo-lookup>
											<div *dxTemplate="let element of 'cellTemplateUomCode'">
												{{element.data.uomcode || '' | filterFromList:uomList : 'code' : 'name' |
												titlecase}}
											</div>
										</dxi-column>

										<dxi-column dataField="price" caption="Unit Price"
											[calculateDisplayValue]="doCurrencyCalculateDisplayValue" [minWidth]="90"
											[width]="'7%'" dataType="number" [allowSorting]="false">
											<div *dxTemplate="let element of 'cellTemplateUnitPrice'">
												{{element.data.price || 0 |
												customCurrencyPipe:customCurrencyOption:customCurrencyOption}}
											</div>
										</dxi-column>

										<dxi-column dataField="quantity" caption="Quantity" cellTemplate="cellTemplateQuantity"
											[minWidth]="80" [width]="'5%'" dataType="number" [allowSorting]="false">
											<div *dxTemplate="let element of 'cellTemplateQuantity'">
												{{element.data.quantity || ''}}
											</div>
										</dxi-column>

										<dxi-column dataField="pickstatus" dataType="string" [visible]="isShowPickStatusColumn"
											caption="Pick Status" [width]="'9%'" [minWidth]="90" [allowEditing]="false"
											[allowSorting]="false" cellTemplate="cellTemplatePickStatus" alignment="center">
											<div *dxTemplate="let element of 'cellTemplatePickStatus'">
												{{(element.data.pickstatus) ? element.data.pickstatus.toString().toLowerCase() ===
												'tyes'
												?
												'Yes' : 'No' : ''}}
											</div>
										</dxi-column>

										<dxi-column dataField="linetotal" caption="Line Total" [allowSorting]="false"
											[width]="'7%'" [minWidth]="100" dataType="number"
											[calculateDisplayValue]="doCurrencyCalculateDisplayValue">
											<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency">
											</dxo-format> -->
											<div *dxTemplate="let element of 'cellTemplateGrossTotal'">
												{{element.data.linetotal || 0 | customCurrencyPipe:customCurrencyOption}}
											</div>
										</dxi-column>

										<div *dxTemplate="let documentline of 'masterDetail'">
											<div
												*ngIf="documentline.data.costassignments && documentline.data.costassignments.length > 0">
												<div class="master-detail-caption"><strong>Cost Assignment : </strong></div>
												<dx-data-grid #costAssignmentDgRef
													[dataSource]="documentline.data.costassignments || []" keyExpr="assignment_id"
													[showBorders]="true" [showColumnLines]="true" [showRowLines]="false"
													[allowColumnResizing]="true" [columnAutoWidth]="true" [width]="'100%'">

													<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
														[useNative]="false"></dxo-scrolling>
													<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
													<dxo-pager [visible]="true" [showPageSizeSelector]="true"
														[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
													</dxo-pager>

													<dxi-column dataField="assignment_type_name" caption="Cost Type"
														dataType="string" [allowHeaderFiltering]="false"></dxi-column>
													<dxi-column dataField="assignment_id" dataType="string" caption="Cost Id"
														[allowHeaderFiltering]="false"></dxi-column>
													<dxi-column dataField="date" caption="Date" dataType="date" format="yyyy-MM-dd"
														[allowHeaderFiltering]="false"></dxi-column>
													<dxi-column dataField="amount" dataType="number" caption="Amount"
														[allowHeaderFiltering]="false"
														[calculateDisplayValue]="doCurrencyCalculateDisplayValue">
														<!-- <dxo-format type="currency" [precision]="decimalPointForCurrency">
														</dxo-format> -->
														<div *dxTemplate="let element of 'cellTemplateAmount'">
															{{element.data.amount || 0 | customCurrencyPipe}}
														</div>
													</dxi-column>
												</dx-data-grid>
											</div>
										</div>

									</dx-data-grid>
								</div>
							</div>

							<div class="cols12 paymentBtmRow">
								<div class="row">
									<diV class="totalRowCal withAddBtn noActClm noBatchClm">
										<div class="alignRight mrgT10">
											<div class="cols12">
												<div class="paySubTotal totalWithAct">
													Subtotal :
													<span class="txtRight">
														{{invoiceDetails.doctotalsys || 0 | customCurrencyPipe:customCurrencyOption }}
													</span>
												</div>
											</div>

											<div *ngIf="invoiceDetails.totaldiscount > 0" class="cols12">
												<div class="paySubTotal totalWithAct">
													Discount :
													<span class="txtRight">
														{{invoiceDetails.totaldiscount || 0 | customCurrencyPipe:customCurrencyOption
														}}
													</span>
												</div>
											</div>

											<div *ngIf="invoiceDetails.totalmiscamount > 0" class="cols12">
												<div class="paySubTotal totalWithAct">
													Miscellaneous Charge:
													<span class="txtRight">
														{{invoiceDetails.totalmiscamount || 0 |
														customCurrencyPipe:customCurrencyOption }}
													</span>
												</div>
											</div>

											<div *ngIf="invoiceDetails.shippingcharges > 0" class="cols12">
												<div class="paySubTotal totalWithAct">
													Frieght Charge:
													<span class="txtRight">
														{{invoiceDetails.shippingcharges || 0 |
														customCurrencyPipe:customCurrencyOption }}
													</span>
												</div>
											</div>

											<div class="cols12">
												<div class="payFinalTotal totalWithAct">Total :
													<span class="txtRight">
														{{invoiceDetails.doctotal || 0 | customCurrencyPipe:customCurrencyOption }}
													</span>
												</div>
											</div>
										</div>
									</diV>

									<div class="cols12 mrgT10 padL30">
										<div class="dx-field feildCol mrgT10 mrgB20 remarksinputDisable">
											<div class="dx-field-label">Remarks</div>
											<div class="dx-field-value" style="width: 100%;">
												<dx-text-area [height]="60" [maxLength]="400" [(value)]="invoiceDetails.comments"
													[disabled]="true">
												</dx-text-area>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</dx-scroll-view>
	</div>
</dx-popup>


<dx-popup [width]="popupWidth" [height]="popupHeight" [showTitle]="true" [dragEnabled]="true"
	[hideOnOutsideClick]="true" title="Payment Information" [showCloseButton]="true" [(visible)]="isShowPaymentInfoPopup"
	(onHidden)="doHidePaymentInfoPopup($event)">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<!-- <dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[elementAttr]="{class: 'scrollViewPopupClassIx' }" width="100%" height="100%"> -->
		<dx-data-grid #listOfPaymentsDgRef [dataSource]="listOfPayments" keyExpr="docnum" [showBorders]="true"
			[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true" [columnAutoWidth]="true"
			[width]="'100%'">
			<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
			<dxo-filter-row [visible]="true"></dxo-filter-row>
			<dxo-header-filter [visible]="true"></dxo-header-filter>
			<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
			<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
				[showInfo]="true">
			</dxo-pager>

			<dxi-column dataField="docnum" caption="Payment Id" dataType="string"
				[allowHeaderFiltering]="false"></dxi-column>
			<dxi-column dataField="type" dataType="string" caption="Payment Type" [allowHeaderFiltering]="true"
				[allowFiltering]="false" cellTemplate="cellTemplatePaymentType">
				<dxo-header-filter [dataSource]="listOfPaymentTypesHeaderFilters"></dxo-header-filter>
				<div *dxTemplate="let element of 'cellTemplatePaymentType'">
					{{ element.data.type || '' | filterFromList:listOfPaymentTypesHeaderFilters : 'value' : 'text'}}
				</div>
			</dxi-column>
			<dxi-column dataField="date" sortOrder="desc" caption="Payment Date" dataType="date" format="yyyy-MM-dd"
				[allowHeaderFiltering]="false"></dxi-column>
			<dxi-column dataField="amount" dataType="number" caption="Payment Amount" [allowFiltering]="false"
				[allowHeaderFiltering]="false">
				<dxo-format type="currency" [precision]="decimalPointForCurrency"> </dxo-format>
			</dxi-column>
			<dxo-summary>
				<dxi-total-item column="amount" summaryType="sum" displayFormat="Total Payment Amount: {0}"
					[customizeText]="doCustomizeTotalPaymentSummary">
					<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
					</dxo-value-format>
				</dxi-total-item>
			</dxo-summary>
		</dx-data-grid>
		<!-- </dx-scroll-view> -->
	</div>
</dx-popup>