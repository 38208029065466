import { Component, OnInit, OnDestroy, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoginService } from '@app/services/login.service';
import { Subject, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { MSG_ERROR_MESSAGE, ServerMethods } from '@app/constants-enums/constants';
import { ResendVerificationEmailReq } from '@app/models/common.model';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router } from '@angular/router';
import { UserManagementService } from '@app/services/user-management-service/user-management-service.service';
import { UserIdleService } from 'angular-user-idle';
import { AccountDetails } from '@app/models/auth';
import { SidemenuService } from '@app/services/sidemenu-service/sidemenu.service';
import { CalledFromEnum, DistributionChannelsCallFromViewEnum } from '@app/constants-enums/enums';
import { UserProfileService } from '@app/services/userprofile-service/userprofile-service.service';
import { environment } from '@environments/environment';
import { DistributionChannelsDxDropDownBoxModel } from '@app/models/input-Components.model';
const EventSource: any = window['EventSource'];
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
	@Output() removeEmailVarifiedSection = new EventEmitter<any>();
	public is_trial: boolean = false;
	public is_staging_environment: boolean = false;
	public email_verified: boolean;
	private matSnackBarRef: any;
	private doDataInReqSubscription: Subscription;
	private emailVerifyTimeout: any;
	private doSubUserSetUpReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;
	private doLogoutReqSbsn: Subscription;
	public mySubscription: any;
	public isAdminUser: boolean = false;
	public unreadNotificationCount: number = 0;
	public syncedServiceMessage: string;
	public isSyncedError: boolean = false;
	private permissionsList: any[] = [];
	public first_name: string;
	public last_name: string;
	public role_name: string;
	public customer_no: string;
	public isVisibleNotificationPopup: boolean = false;
	public isGetUnreadNotificationList: boolean = false;
	public popupWidth: number;
	public popupHeight: number;
	public isShowLogoutPopup: boolean;
	public portalType: string;
	public cardViewMultiSettlementProps: any;
	public isShowSettlementCardView: boolean = false;
	public is_sync_status: boolean = false;
	public startSyncStatus: boolean = true;
	public isEnableDistributionChannels: boolean = false;
	public checkISyncProcessIntervalCall: any;
	public distributionChannelsModel: DistributionChannelsDxDropDownBoxModel = new DistributionChannelsDxDropDownBoxModel();
	constructor(private dialog: MatDialog,
		private _LoginService: LoginService,
		public _AppCommonSrvc: AppCommonSrvc,
		private _ToastrService: ToastrService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _GlobalStateService: GlobalStateService,
		private _NgxPermissionsService: NgxPermissionsService,
		private _Router: Router,
		private _UserManagementService: UserManagementService,
		private _UserProfileService: UserProfileService,
		private _SidemenuService: SidemenuService,
		private _UserIdleService: UserIdleService) {
		// this._Router.routeReuseStrategy.shouldReuseRoute = function () {
		// 	return false;
		// };
		// this.mySubscription = this._Router.events.subscribe((event) => {
		// 	if (event instanceof NavigationEnd) {
		// 		// Trick the Router into believing it's last link wasn't previously loaded
		// 		this._Router.navigated = false;
		// 	}
		// });
		this.distributionChannelsModel.callFromView = DistributionChannelsCallFromViewEnum.HEADER;
	}

	ngOnInit() {

		// Get current permissions
		const permissions = this._NgxPermissionsService.getPermissions();
		for (const per in permissions) {
			this.permissionsList.push(per);
		}
		let strURL = environment.URL_SERVER.split('//')[1].split('.')[0] || '';
		if (strURL) {
			this.is_staging_environment = strURL === 'staging' ? true : false;
		}
		let isEnableDistributionChannels = false;
		try {
			isEnableDistributionChannels = this._LoginService.loginUser.account_detail.enable_distribution_channels;
		} catch (e) {
			isEnableDistributionChannels = false;
		}
		this.isEnableDistributionChannels = isEnableDistributionChannels;
		this._GlobalStateService.unsubscribe('CALLED_ON_ENABLE_DISTRIBUTION_CHANNEL_CONFIG');
		this._GlobalStateService.subscribe('CALLED_ON_ENABLE_DISTRIBUTION_CHANNEL_CONFIG', response => {
			if (response) {
				this.isEnableDistributionChannels = response.isEnable;
			}
		});
		// End Get current permissions
		this.is_trial = this._LoginService.loginUser.account_detail.is_trial;
		this.email_verified = this._LoginService.loginUser.account_detail.email_verified;
		this.isAdminUser = this._LoginService.loginUser.account_detail.admin;
		this.removeEmailVarifiedSection.emit(this.email_verified);
		// call while user converting trial to purchase then update is_tiral flag (Hide TRIAL image)
		this._GlobalStateService.unsubscribe('CALL_CONVERT_TRIAL_TO_PURCHASE');
		this._GlobalStateService.subscribe('CALL_CONVERT_TRIAL_TO_PURCHASE', response => {
			if (response) {
				if (response.isDefault) {
					this.is_trial = this._LoginService.loginUser.account_detail.is_trial;
				}
			}
		});

		this._GlobalStateService.subscribe('unreadNotificationCounter', (response) => {
			this.unreadNotificationCount = 0;
			if (response.isResponse) {
				this.unreadNotificationCount = response.data.unread_notification_count;
			}
		});

		this._GlobalStateService.subscribe('syncedWithErpMessage', (response) => {
			this.syncedServiceMessage = '';
			this.isSyncedError = false;
			if (response.isResponse) {
				this.syncedServiceMessage = response.data.service_message;
				this.isSyncedError = response.data.service_error_found;
			}
		});

		this._GlobalStateService.subscribe('UserInfoChanged', (response) => {
			if (response) {
				this.getUserInfo();
			}
		});
		this._GlobalStateService.notifyDataChangedDuplicate('UserInfoChanged', new Date().getTime());

		this._GlobalStateService.unsubscribe('VIEW_CLOSED_MULTIPLE_SETTLEMENT_POPUP');
		this._GlobalStateService.subscribe('VIEW_CLOSED_MULTIPLE_SETTLEMENT_POPUP', response => {
			if (response) {
				if (response.isDefault) {
					this.doOpenCloseSettlementCardView(response);
				}
			}
		});

		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.popupHeight = heightWidthRatio.innerHeight;
		this.popupWidth = heightWidthRatio.innerWidth;
		this.portalType = this._LoginService.loginUser.account_detail.portal_type;
		this.checkISyncProcess();
	}

	ngAfterViewInit(): void {
		// code task notification show condtion
		const userCalledFrom = this._UserProfileService.getCalledFrom()
		const objNotification = this._LoginService.loginUser.account_detail['notification'];
		let isPushAlert: boolean = false;
		if (objNotification && objNotification['notify_by']) {
			if (objNotification['notify_by'].length > 0) {
				const indexNotification = objNotification['notify_by'].findIndex(i => i === 'push_alert');
				if (indexNotification !== -1) {
					isPushAlert = true;
				}
			}
		}
		//this.isVisibleNotificationPopup = true;
		// if (userCalledFrom === CalledFromEnum.CALLED_AS_UPDATE_PACKAGE) {
		// 	if (isPushAlert && !this.isGetUnreadNotificationList) {
		// 		this.isVisibleNotificationPopup = true;
		// 	}
		// } else {
		// 	this._GlobalStateService.unsubscribe('CALL_NOTIFICATION_POP_UP');
		// 	this._GlobalStateService.subscribe('CALL_NOTIFICATION_POP_UP', response => {
		// 		if (response) {
		// 			if (response.isDefault) {
		// 				if (isPushAlert && !this.isGetUnreadNotificationList) {
		// 					this.isVisibleNotificationPopup = true;
		// 				}
		// 			}
		// 		}
		// 	});
		// }
		// end code task notification show condtion
	}

	ngOnDestroy(): void {
		clearInterval(this.checkISyncProcessIntervalCall);
		if (this.doDataInReqSubscription) {
			this.doDataInReqSubscription.unsubscribe();
		}
		if (this.matSnackBarRef) {
			this.matSnackBarRef.dismiss();
		}
		if (this.emailVerifyTimeout) {
			clearTimeout(this.emailVerifyTimeout);
		}
		if (this.doSubUserSetUpReqSubscription) {
			this.doSubUserSetUpReqSubscription.unsubscribe();
		}
		if (this.mySubscription) {
			this.mySubscription.unsubscribe();
		}
		if (this.doLogoutReqSbsn) {
			this.doLogoutReqSbsn.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		this._GlobalStateService.unsubscribe('CALL_CONVERT_TRIAL_TO_PURCHASE');
		this._GlobalStateService.unsubscribe('unreadNotificationCounter'); // Dont Removed
		this._GlobalStateService.unsubscribe('syncedWithErpMessage'); // Dont Removed
		this._GlobalStateService.unsubscribe('UserInfoChanged'); // Dont Removed
		this._GlobalStateService.unsubscribe('CALL_NOTIFICATION_POP_UP'); // Dont Removed
		this._GlobalStateService.unsubscribe('VIEW_CLOSED_MULTIPLE_SETTLEMENT_POPUP'); // Dont Removed
		this._GlobalStateService.unsubscribe('CALLED_ON_ENABLE_DISTRIBUTION_CHANNEL_CONFIG');
	}

	@HostListener('window:resize', ['$event'])
	public onResize(event) {
		const heightWidthRatio = this._AppCommonSrvc.getPopupHeightWidthRatio();
		this.popupHeight = heightWidthRatio.innerHeight;
		this.popupWidth = heightWidthRatio.innerWidth;
	}

	// Method to Resend Email for Not verified user
	public resendVerificationEmail() {
		const resendVerificationEmailReq = new ResendVerificationEmailReq();
		resendVerificationEmailReq.method = 'resendVerificationEmail';
		resendVerificationEmailReq.token = this._LoginService.loginUser.token;
		resendVerificationEmailReq.usr = this._LoginService.loginUser.user;
		const formDataReq = new FormData();
		for (const keyName in resendVerificationEmailReq) {
			formDataReq.append(keyName, resendVerificationEmailReq[keyName]);
		}
		if (this.doSubUserSetUpReqSubscription) {
			this.doSubUserSetUpReqSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.doSubUserSetUpReqSubscription = this._RestApiService.doSubUserSetUpReqFormData(formDataReq).subscribe(response => {
			this._LoaderService.hide();
			if (response.flag) {
				this._ToastrService.success(response.message, 'Success', { closeButton: true, tapToDismiss: true });
			} else {
				if (response.errorCode !== '1000') {
					this._ToastrService.error(response.message, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}
		}, error => {
			this._LoaderService.hide();
			this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
		});
	}

	public closeEmailVerification() {
		this.email_verified = true;
		this.removeEmailVarifiedSection.emit(this.email_verified);
	}

	// Method used to Click on Notification
	public notificationBarclick() {
		this._AppCommonSrvc.setNotificationTab('errorlog');
		this._AppCommonSrvc.setNotificationSubTab('');
		this._Router.navigate(['/notifications/errorslist']);
	}

	// Method used to get User Information
	private getUserInfo() {
		let accountDetails: AccountDetails;
		try {
			accountDetails = JSON.parse(JSON.stringify(this._LoginService.loginUser.account_detail));
		} catch (e) { }

		if (Object.keys(accountDetails).length > 0) {
			this.first_name = accountDetails.first_name;
			this.last_name = accountDetails.last_name;
			this.role_name = accountDetails.role_name;
			this.customer_no = accountDetails.usr;
		}
	}

	// Method used to redirect user by passing action
	public doRedirectUser(actionToRedirect: string) {
		this._SidemenuService.setCurrentMenu('dashboard');
		switch (actionToRedirect) {
			// #region for USER MANAGEMENT
			case 'userprofile':
				this._UserManagementService.setTabSelection('userprofile');
				this._Router.navigate(['/usermanagements/userprofile']);
				break;
			case 'currentplan':
				this._UserManagementService.setTabSelection('currentplan');
				this._Router.navigate(['/usermanagements/currentplan']);
				break;
			case 'companyinfo':
				this._UserManagementService.setTabSelection('companyinfo');
				this._Router.navigate(['/usermanagements/companyinfo']);
				break;
			case 'billinghistory':
				this._UserManagementService.setTabSelection('billinghistory');
				this._Router.navigate(['/usermanagements/billinghistory']);
				break;
			default:
			// #endregion
		}
	}

	// Method used to Logout the user
	// public doLogout() {
	// 	this.isShowLogoutPopup = false;
	// 	setTimeout(() => {
	// 		this.isShowLogoutPopup = true;
	// 	}, 300);
	// }

	// Method used to Logout the user
	public doLogout() {
		if (this._LoginService.loginUser && this._LoginService.loginUser.user) {
			let formdata = 'usr=' + this._LoginService.loginUser.user;
			formdata = formdata + '&token=' + this._LoginService.loginUser.token;
			formdata = formdata + '&logout=1';

			if (this.doLogoutReqSbsn) {
				this.doLogoutReqSbsn.unsubscribe();
			}
			this._LoaderService.show();
			this.doLogoutReqSbsn = this._RestApiService.doLoginReq(formdata).subscribe(response => {
				this._LoaderService.hide();
				this._AppCommonSrvc.doClearAllSetterGetter();
				this._NgxPermissionsService.flushPermissions();
				this._UserIdleService.stopTimer();
				this._UserIdleService.stopWatching();
				this._LoginService.loginUser = undefined;
				this._GlobalStateService.notifyDataChangedDuplicate('TOKEN_REFRESHER_INTERVAL_EVENT', new Date().getTime());
				this._Router.navigate(['/auth/login'], { replaceUrl: true });
			}, error => {
				this._LoaderService.hide();
				console.error(error);
			});
		} else {
			this._Router.navigate(['/auth/login'], { replaceUrl: true });
		}
	}

	getUnreadNotificationMessage($event) {
		this.isGetUnreadNotificationList = true;
	}
	public doHideLogoutPopup(event: any) {
	}

	public doCancelLogoutPopup(event: any) {
		this.isShowLogoutPopup = false;
	}

	public doOpenCloseSettlementCardView(response: any) {
		this.cardViewMultiSettlementProps = {
			callFrom: 'close-multiple-settlement',
			selectedMultiSettlementRowData: response.selectedRowData
		};
		this.isShowSettlementCardView = false;
		setTimeout(() => {
			this.isShowSettlementCardView = true;
		}, 300);
	}

	public doCloseCardViewMultipleSettlementPopup(e) {
		this.isShowSettlementCardView = false;
	}
	public startISyncProcessIntervalCall() {
		this.checkISyncProcessIntervalCall = setInterval(() => {
			this.checkISyncProcess();
		}, 300000);
	}

	public checkISyncProcess() {
		const reqFormData = new FormData();
		reqFormData.append('usr', this._LoginService.loginUser.user);
		reqFormData.append('token', this._LoginService.loginUser.token);
		reqFormData.append('method', ServerMethods.GET_SYNC_STATUS);
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
			next: (response) => {
				if (response && response.flag) {
					const responseData: any = response.data || [];
					if (responseData) {
						this.is_sync_status = responseData.sync_status;
					}
				}
				if (this.startSyncStatus) {
					this.startSyncStatus = false;
					this.startISyncProcessIntervalCall();
				}
			},
			error: (error) => {
			}
		});
	}
}
