<div class="row chargecontent" *ngxPermissionsOnly="isCurrentTabPermission">
	<!-- <div class="cols6">
		<div class="feildCol inputDisableFile">
			<div class="fileUpbtn">
				<input type="file" [(ngModel)]="chargeDocumentsTabModel.documentURL"
					accept=".doc,.docx,.csv,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf"
					(change)="uploadDocument($any($event.target).files)" />
				<span class="fileNamelabel">{{chargeDocumentsTabModel.documentURL}}</span>
				<span class="upldBtn">Browse</span>
			</div>
			<div class="inputField">
				<label for="rate">Document</label>
			</div>
		</div>
	</div>
	<div class="cols6">
		<div class="feildCol">
			<input name="" type="text" id="documentReferenceNumber"
				[(ngModel)]="chargeDocumentsTabModel.documentReferenceNumber">
			<div class="inputField">
				<label for="documentReferenceNumber">Document Reference No.</label>
			</div>
		</div>
	</div>
	<div class="cols10 cols10addbtn">
		<div class="feildCol">
			<input name="" type="text" id="claimedUserNote" [(ngModel)]="chargeDocumentsTabModel.claimedUserNote">
			<div class="inputField">
				<label for="claimedUserNote">User Note</label>
			</div>
		</div>
	</div> -->
	<div class="d-flex padL15 padR15">

		<div class="inlineRadio padT5">
			<div class="notificationRadio mrgR0 jurisdictionTaxicon">
				<span class="mainLabel">Hide Quantity</span>
				<dx-switch [(value)]="hide_quantity">
				</dx-switch>
			</div>
		</div>

		<div class="flex-grow-1">
			<div class="cols3 padR0">
				<div *ngIf="!isCustomerChargebackbasis">
					<div class="dx-field feildCol">
						<div class="dx-field-label">End-Users</div>
						<div class="dx-field-value">
							<dx-drop-down-box [dataSource]="enduserDataSource" [(value)]="lengthSelectedEndusers"
								valueExpr="code" [displayExpr]="displayExprEnduserName" placeholder="Select End-Users..."
								[dropDownOptions]="{width:650}" [showClearButton]="true"
								(onValueChanged)="onEnduserValueChanged($event)">
								<div *dxTemplate="let data of 'content'">
									<!-- <dx-check-box text="All End-Users" (onValueChanged)="checkAllCustomer()" [(value)]="chkAllCustomer"
									class="mrgB10">
								</dx-check-box> -->
									<dx-tag-box [(value)]="arrSelectedEnduserList" [grouped]="true"></dx-tag-box>
									<dx-data-grid #enduserDataGrid [dataSource]="enduserDataSource"
										[columns]="enduserGridBoxColumns" [hoverStateEnabled]="true" [allowColumnResizing]="true"
										[paging]="{ enabled: true, pageSize: 10 }" keyExpr="name" [filterRow]="{ visible: true }"
										[height]="345" [(selectedRowKeys)]="arrSelectedEnduserList" [showBorders]="true"
										[remoteOperations]="false" (onSelectionChanged)="onEndusersSelectionChanged($event)">
										<dxo-selection mode="multiple" [allowSelectAll]="false"></dxo-selection>
										<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
									</dx-data-grid>
								</div>
							</dx-drop-down-box>
						</div>
					</div>
				</div>
			</div>
			<div class="cols3 padR0">
				<div class="dx-field feildCol">
					<div class="dx-field-label">Reason Code </div>
					<div class="dx-field-value">
						<dx-drop-down-box [(value)]="reasonCodeGridBoxValue" valueExpr="guid"
							[displayExpr]="displayExprReasonCode" placeholder="Select Reason Code..."
							[(opened)]="isReasonCodeDropDownBoxOpened" [showClearButton]="true" [deferRendering]="false"
							[dataSource]="listOfCustomReason" (onOptionChanged)="onReasonCodeSelectionChanged($event)"
							[dropDownOptions]="{width:400}">
							<div *dxTemplate="let data of 'content'">
								<dx-data-grid #resonCodeDataGrid [dataSource]="listOfCustomReason" keyExpr="guid"
									[columns]="reasonCodeGridBoxColumns" [hoverStateEnabled]="true" height="100%"
									[(selectedRowKeys)]="reasonCodeGridBoxValue" [allowColumnResizing]="true">
									<dxo-selection mode="single"></dxo-selection>
									<dxo-filter-row [visible]="true"></dxo-filter-row>
									<dxo-scrolling mode="virtual" [scrollByThumb]="true" [showScrollbar]="'always'"
										[useNative]="false"></dxo-scrolling>
									<dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
								</dx-data-grid>
							</div>
						</dx-drop-down-box>
					</div>
				</div>
			</div>
			<div class="cols3 padR0">
				<div class="datePicker">
					<div class="dx-field feildCol">
						<div class="dx-field-label">Cut-Off Date</div>
						<dx-date-box [(value)]="cut_off_date" type="date" displayFormat="yyyy-MM-dd"
							[min]="minMaxEndUsersChargebackContractModelUI?.startDate || nowDate"
							[max]="minMaxEndUsersChargebackContractModelUI?.endDate || nowDate">
						</dx-date-box>
					</div>
				</div>
			</div>
			<div class="cols3">
				<div class="datePicker">
					<div class="dx-field feildCol">
						<div class="dx-field-label">Transaction Date</div>
						<div class="dx-field-value">
							<dx-date-box [(value)]="transaction_date" type="date" displayFormat="yyyy-MM-dd">
							</dx-date-box>
						</div>
					</div>
				</div>
			</div>
		</div>
		<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
			text="Action" class="secondaryBtn actionTextWithIconBtnIx alignRight" [items]="listOfActions"
			[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
			(onButtonClick)="doActionsBtnClick($event)" (onItemClick)="doActionsBtnItemClick($event)"
			[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
	</div>
</div>
<div class="mrgT10">
	<dx-data-grid #chargeDocumentsTabDataGridContainer id="chargeDocumentsTabDataGridContainer"
		[dataSource]="chargeDocumentsTabDataSource" [remoteOperations]="false" [showBorders]="true"
		[allowColumnResizing]="true" [columnAutoWidth]="true" (onToolbarPreparing)="doToolbarPreparing($event)"
		(onRowUpdated)="doOnRowUpdatedChargeDocumentsTab($event)"
		(onRowClick)="viewChargeBackDocumentDetails($event.data);" (onSaved)="doOnSavedChargeDocumentsTab()"
		[width]="'100%'" keyExpr="guid" class="hideFilterPanelInx">
		<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
		<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
			[showInfo]="true">
		</dxo-pager>
		<dxo-sorting mode="single"></dxo-sorting>
		<dxo-filter-row [visible]="true"></dxo-filter-row>
		<dxo-filter-panel [visible]="true"></dxo-filter-panel>
		<dxo-header-filter [visible]="true"></dxo-header-filter>
		<!-- <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling> -->

		<!-- <dxo-editing mode="row" [allowUpdating]="false" [allowDeleting]="true" [allowAdding]="false"></dxo-editing> -->
		<dxi-column dataField="ixcode" [allowHeaderFiltering]="false" caption="Claim No."
			cellTemplate="cellTemplateClaimId" alignment="left" dataType="string" sortOrder="desc">
			<div *dxTemplate="let element of 'cellTemplateClaimId'">
				<span class="highlight pointerMark"> {{element.data.ixcode || ''}}</span>
			</div>
		</dxi-column>
		<dxi-column dataField="claimtype" caption="Claim Type" dataType="string" [allowHeaderFiltering]="true"
			cellTemplate="cellTemplateClaimType" [allowFiltering]="false">
			<div *dxTemplate="let element of 'cellTemplateClaimType'">
				{{element.data.claimtype == 'manual_adjustment' ? 'Manual Adjustment' : 'Regular'}}
			</div>
		</dxi-column>
		<dxi-column dataField="reason_code_guid" caption="Reason Code" dataType="string" [allowHeaderFiltering]="true"
			cellTemplate="cellTemplateReasonCode" [allowFiltering]="false">
			<dxo-header-filter [dataSource]="listOfHeaderCustomReason">
			</dxo-header-filter>
			<div *dxTemplate="let element of 'cellTemplateReasonCode'">
				{{element.data.reason_code_guid || '' | filterFromList:listOfCustomReason : 'guid' : 'label'}}
				({{element.data.reason_code_guid || '' | filterFromList:listOfCustomReason : 'guid' : 'erpcode'}})
			</div>
		</dxi-column>
		<dxi-column caption="End-User Name" [allowHiding]="false" dataField="endusersList" dataType="string"
			[allowEditing]="false" [allowFiltering]="true" [allowHeaderFiltering]="false"
			cellTemplate="cellTemplateEndUserName">
			<div *dxTemplate="let element of 'cellTemplateEndUserName'">
				<span>
					{{element.data.endusersList && element.data.endusersList.length > 0 ?
					element.data.endusersList[0].end_user_name : ''}}
					<span [attr.id]="'endUserToolTip_' + element.data.guid"
						*ngIf="element.data.endusersList[0] && (element.data.endusersList.length === 2 || element.data.endusersList.length >= 3)">
						+ {{element.data.endusersList.length-1}}
						{{ element.data.endusersList.length === 2 ? 'Other' : element.data.endusersList.length >= 3 ?
						'Others' : ''}}
					</span>
					<dx-tooltip target="#endUserToolTip_{{element.data.guid}}" showEvent="dxhoverstart"
						hideEvent="dxhoverend" position="right">
						<div *dxTemplate="let d = d; of: 'content'">
							<div class="tooltip-wrapper">
								<div class="greenTipout">
									<ol class="tipList">
										<li *ngFor="let enduser of element.data.endusersList; let p = index;">
											<a>{{enduser.end_user_name || ''}}</a>
										</li>
									</ol>
								</div>
							</div>
						</div>
					</dx-tooltip>
				</span>
			</div>
		</dxi-column>
		<!-- <dxi-column caption="Document Name" dataField="documentOriginalName" [allowEditing]="false"
			cellTemplate="cellTemplateDocumentName" [allowHeaderFiltering]="false" [allowFiltering]="true"
			[allowSorting]="true" dataType="string">
			<div *dxTemplate="let element of 'cellTemplateDocumentName'">
				{{element.data.documentOriginalName || ''}}
			</div>
		</dxi-column>
		<dxi-column caption="Document Reference No." dataField="documentReferenceNumber" [allowEditing]="false"
			cellTemplate="cellTemplatedocumentReferenceNumber" [allowHeaderFiltering]="false" [allowFiltering]="true"
			[allowSorting]="true" dataType="string">
			<div *dxTemplate="let element of 'cellTemplatedocumentReferenceNumber'">
				{{element.data.documentReferenceNumber || ''}}
			</div>
		</dxi-column>
		<dxi-column caption="Claim Date" dataField="claimDate" [allowEditing]="false" cellTemplate="cellTemplateClaimDate"
			[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true" dataType="string">
			<div *dxTemplate="let element of 'cellTemplateClaimDate'">
				{{ (element.data.claimDate !== null && element.data.claimDate !== '' && element.data.claimDate !==
				undefined && element.data.claimDate !== 'Invalid date') ? (element.data.claimDate | toDateObj | date:
				'yyyy-MM-dd') : "" }}
			</div>
		</dxi-column> -->
		<dxi-column caption="Chargeback Period" dataField="chargeback_period" [allowEditing]="false"
			cellTemplate="cellTemplateChargeback" [allowHeaderFiltering]="false" [allowFiltering]="true"
			[allowSorting]="true" dataType="string">
			<div *dxTemplate="let element of 'cellTemplateChargeback'">
				{{ (element.data.chargeback_period !== null && element.data.chargeback_period !== '' &&
				element.data.chargeback_period !==
				undefined && element.data.chargeback_period !== 'Invalid date') ? (element.data.chargeback_period |
				toDateObj | date:'MMMM,yyyy') : "" }}
			</div>
		</dxi-column>
		<dxi-column caption="Document Date" dataField="documentDate" [allowEditing]="false"
			cellTemplate="cellTemplatedocumentDocumentDate" [allowHeaderFiltering]="false" [allowFiltering]="true"
			[allowSorting]="true" dataType="string">
			<div *dxTemplate="let element of 'cellTemplatedocumentDocumentDate'">
				{{ (element.data.documentDate !== null && element.data.documentDate !== '' && element.data.documentDate
				!==
				undefined && element.data.documentDate !== 'Invalid date') ? (element.data.documentDate | toDateObj |
				date:
				'yyyy-MM-dd') : "" }}
			</div>
		</dxi-column>
		<dxi-column caption="Cut-Off Date" dataField="cut_off_date" [allowEditing]="false"
			cellTemplate="cellTemplatedocumentCutOffDate" [allowHeaderFiltering]="false" [allowFiltering]="true"
			[allowSorting]="true" dataType="string">
			<div *dxTemplate="let element of 'cellTemplatedocumentCutOffDate'">
				{{ (element.data.cut_off_date !== null && element.data.cut_off_date !== '' && element.data.cut_off_date
				!==
				undefined && element.data.cut_off_date !== 'Invalid date') ? (element.data.cut_off_date | toDateObj |
				date:
				'yyyy-MM-dd') : "" }}
			</div>
		</dxi-column>
		<dxi-column caption="Transaction Date" dataField="transaction_date" [allowEditing]="false"
			cellTemplate="cellTemplateTransactionDate" [allowHeaderFiltering]="false" [allowFiltering]="true"
			[allowSorting]="true" dataType="string">
			<div *dxTemplate="let element of 'cellTemplateTransactionDate'">
				{{ (element.data.transaction_date !== null && element.data.transaction_date !== '' &&
				element.data.cut_off_date
				!==
				undefined && element.data.transaction_date !== 'Invalid date') ? (element.data.transaction_date | toDateObj
				|
				date:
				'yyyy-MM-dd') : "" }}
			</div>
		</dxi-column>
		<dxi-column caption="Total Claimed Amount" dataField="documentTotal" [allowEditing]="false"
			[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true" dataType="number">
			<dxo-format type="currency" [precision]="decimalPointForCurrency">
			</dxo-format>
			<div *dxTemplate="let element of 'cellTemplateTotalClaimedAmount'">
				{{element.data.documentTotal|| 0 | customCurrencyPipe}}
			</div>
		</dxi-column>
		<!-- <dxi-column caption="Status" dataField="claimedStatus" [allowEditing]="false" cellTemplate="cellTemplateStatus"
			[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
			<div *dxTemplate="let element of 'cellTemplateStatus'" style="text-transform: uppercase;"
				[ngClass]="element.data.claimedStatus === 'approved' ? 'green' : element.data.claimedStatus === 'inprocess' ? 'orange' : 'red'">
				{{element.data.claimedStatus}}
			</div>
		</dxi-column> -->
		<dxi-column dataField="created_by_name" caption="Created By" dataType="string" [allowHeaderFiltering]="true"
			cellTemplate="cellTemplateCreatedBy" [allowFiltering]="false">
			<div *dxTemplate="let element of 'cellTemplateCreatedBy'">
				{{element.data.created_by_name}}
			</div>
		</dxi-column>
		<dxi-column caption="Remark/Memo" dataField="remark" cellTemplate="cellTemplateRemark" dataType="string"
			[allowHeaderFiltering]="false" [allowFiltering]="false" [allowEditing]="false">
			<div *dxTemplate="let element of 'cellTemplateRemark'">
				{{element.data.remark || ''}}
			</div>
		</dxi-column>
		<dxi-column caption="Status" dataField="claimedStatus" [allowEditing]="false" cellTemplate="cellTemplateStatus"
			[allowHeaderFiltering]="true" [allowFiltering]="true" [allowSorting]="true" dataType="string">
			<dxo-header-filter [dataSource]="listOfHeaderFilterStatuses"></dxo-header-filter>
			<div *dxTemplate="let element of 'cellTemplateStatus'">
				<div [ngClass]="element.data.claimedStatus || '' | filterFromList:listOfStatuses : 'value' : 'classname'">
					{{element.data.claimedStatus || '' | filterFromList:listOfStatuses : 'value' :'text'}}
				</div>
			</div>
		</dxi-column>
		<dxi-column dataField="guid" caption="Action" [allowEditing]="false" cellTemplate="cellTemplateAction"
			[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="false" [allowExporting]="false"
			[fixed]="true" fixedPosition="right" [allowHiding]="false">
			<div *dxTemplate="let element of 'cellTemplateAction'" class="center actBtn">
				<a (click)="viewChargeBackDocumentDetails(element.data);$event.stopPropagation();">
					<span class="icon-view" id="viewDocument_{{element.rowIndex + 1 }}"></span>
				</a>
				<!-- <a (click)="downloadChargeBackDocument(element.data);$event.stopPropagation();">
					<span class="icon-download" id="downloadAttachedDocument_{{element.rowIndex + 1 }}"></span>
				</a> -->
				<a name="delete" *ngIf="element.data.claimedStatus === 'draft'"
					(click)="doDeleteChargeDocumentsTab(element.data)">
					<span class="icon-deletedxtable"></span></a>

				<dx-tooltip target="#viewDocument_{{element.rowIndex + 1 }}" showEvent="dxhoverstart" hideEvent="dxhoverend"
					position="bottom">
					<div *dxTemplate="let data = data of 'content'">
						View Document
					</div>
				</dx-tooltip>
				<dx-tooltip target="#downloadAttachedDocument_{{element.rowIndex + 1 }}" showEvent="dxhoverstart"
					hideEvent="dxhoverend" position="bottom">
					<div *dxTemplate="let data = data of 'content'">
						Download Attached Document
					</div>
				</dx-tooltip>

			</div>
		</dxi-column>
	</dx-data-grid>

	<!--<div *ngIf="isUploadExcelCsvFileItmGrpTab" class="noteBx mrgT10 mrgB0">
		<span>
			<b>{{noOfUnmatchedClaim || 0}}</b> Item(s) out of <b>{{noOfTotaClaim || 0}}</b> Item(s)
			ignored
			<span class="noteSeprator"></span>
			<b>{{ noOfMatchedClaim|| 0}}</b> Item(s) out of <b>{{noOfTotaClaim || 0}}</b> Item(s)
			successful
			<strong (click)="doViewImportClaimItemsExcel()" class="pointerMark">Click here to
				view</strong>
		</span>
	</div>-->
</div>
<app-upload-chargeback-enduser-claims-dx-dialog [loadUploadEndUserDxDialogSubject]="loadUploadEndUserDxDialogSubject"
	(hidePopUpDataEvent)="doHideUploadEndUserDxDialog($event)"></app-upload-chargeback-enduser-claims-dx-dialog>
<!-- <app-mmeucc-view-chargeback-documents-dx-popup (hideViewClaimPopUpDataEvent)="doHideViewClaimDxDialog($event)"
	[loadViewChargebackDocDxDialogSubject]="loadViewChargebackDocDxDialogSubject"></app-mmeucc-view-chargeback-documents-dx-popup> -->
<app-view-claim-preview-popup *ngIf="isShowMinMaxChargebackClaimPreviewPopup"
	[claimPreviewProps]="minMaxCharebackClaimPreviewProps" (closeClaimPopup)="doCloseMinMaxChargebackClaimPopup($event)">
</app-view-claim-preview-popup>
<app-mmeucc-manual-adjustment-popup *ngIf="isShowManualAdjustmentPopup" [claimPreviewProps]="manualAdjustmentProps"
	(closeClaimManualAdjustmentPopup)="doCloseClaimManualAdjustmentPopup($event)">
</app-mmeucc-manual-adjustment-popup>
<app-mmeucc-claimed-items-list-dialog *ngIf="isShowClaimedItemlstPopup" [claimedItemslstProps]="claimedItemslstProps"
	(closeClaimedItemsLstPopup)="doCloseClaimedItemsLstPopup($event)">
</app-mmeucc-claimed-items-list-dialog>
<app-upload-chargeback-enduser-claims-dialog *ngIf="isShowUploadChargebackEnduserClaimsPopup"
	[uploadChargebackEndUserClaimsProps]="uploadChargebackEndUserClaimsProps"
	(closeUploadChargebackEnduserClaimsPopup)="doCloseUploadChargebackEnduserClaimsPopup($event)"></app-upload-chargeback-enduser-claims-dialog>