import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AS_USER_MANAGEMENT, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods, ServerSections } from '@app/constants-enums/constants';
import { DistributionChannelsCallFromViewEnum } from '@app/constants-enums/enums';
import { PageOptions } from '@app/models/common.model';
import { Guid } from '@app/models/guid';
import { DistributionChannelsDxDropDownBoxModel } from '@app/models/input-Components.model';
import { UserManagementUI } from '@app/models/usermanagement.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { AppStateService } from '@app/services/app-state/app-state.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { InputControlService } from '@app/services/input-control-service/input-control-service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent, DxDropDownBoxComponent, DxTagBoxComponent } from 'devextreme-angular';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
@Component({
	selector: 'app-distribution-channels-dx-drop-down-box',
	templateUrl: './distribution-channels-dx-drop-down-box.component.html',
	styleUrls: ['./distribution-channels-dx-drop-down-box.component.css']
})
export class DistributionChannelsDxDropDownBoxComponent implements OnInit, OnDestroy {
	@Input('distributionChannelsModel') distributionChannelsModel: DistributionChannelsDxDropDownBoxModel;
	@ViewChild("distributionChannelsDropDown", {}) distributionChannelsDropDown: DxDropDownBoxComponent;
	@ViewChild("distributionChannelsDataGrid", {}) distributionChannelsDataGrid: DxDataGridComponent;
	@ViewChild("distributionChannelsTagBox", {}) distributionChannelsTagBox: DxTagBoxComponent;
	public pageOptions: PageOptions = new PageOptions();
	public parentLevelKey = ServerSections.USER_DISTRIBUTION_CHANNELS;
	private dataOutReqSubscription: Subscription;
	private updateUserSettingSbsn: Subscription;
	public userDistributionChannelsCode: any = [];
	private guid = new Guid();
	public DistributionChannelsCallFromViewEnum = DistributionChannelsCallFromViewEnum;
	public isCallFromViewCondtion: boolean = false;
	constructor(public router: Router,
		public _InputControlService: InputControlService,
		public _LoginService: LoginService,
		public _ToastrService: ToastrService,
		public _AppCommonSrvc: AppCommonSrvc,
		public _AppStateService: AppStateService,
		public _GlobalStateService: GlobalStateService,
		public _RestApiService: RestApiService) {
	}

	ngOnInit(): void {
		if (this._LoginService.loginUser && this._LoginService.loginUser.account_detail && this._LoginService.loginUser.account_detail.hasOwnProperty('distribution_channels')) {
			//if (this._LoginService.loginUser.account_detail.distribution_channels.length > 0) {
			this.userDistributionChannelsCode = this._LoginService.loginUser.account_detail.distribution_channels || [];
			//}
		}
		this.isCallFromViewCondtion = (this.distributionChannelsModel.callFromView === DistributionChannelsCallFromViewEnum.ADD_EDIT_PRICELIST) ||
			(this.distributionChannelsModel.callFromView === DistributionChannelsCallFromViewEnum.ADD_EDIT_CRM) ||
			(this.distributionChannelsModel.callFromView === DistributionChannelsCallFromViewEnum.ADD_EDIT_QUOTE) ||
			(this.distributionChannelsModel.callFromView === DistributionChannelsCallFromViewEnum.ADD_EDIT_ORDER) ||
			(this.distributionChannelsModel.callFromView === DistributionChannelsCallFromViewEnum.ADD_EDIT_END_USERS) ||
			(this.distributionChannelsModel.callFromView === DistributionChannelsCallFromViewEnum.MIN_MAX_CHARGEBACK_CONTRACT) ||
			(this.distributionChannelsModel.callFromView === DistributionChannelsCallFromViewEnum.ADD_EDIT_REBATE) ||
			(this.distributionChannelsModel.callFromView === DistributionChannelsCallFromViewEnum.ADD_EDIT_ROYALTY) ||
			(this.distributionChannelsModel.callFromView === DistributionChannelsCallFromViewEnum.COMMISSION_TIERBASE_CONTRACT);

		if (this.distributionChannelsModel.callFromView === DistributionChannelsCallFromViewEnum.HEADER) {
			this.loadAssignDistributionChannelsDataList(); //getting from user accounts
		} else if (this.isCallFromViewCondtion) {
			this.doLoadAssignDistributionChannelsDataList();
		} else {
			this.loadDistributionChannelsDataList();
		}
	}

	ngOnDestroy() {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
	}

	loadAssignDistributionChannelsDataList() {
		let lstAllDistributionChannels = [];
		let lstAssignDistributionChannels = [];

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.DISTRIBUTION_CHANNELS);
		formData.append('is_dropdown', 'true');
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
			next: (response) => {
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						lstAllDistributionChannels = response.data;
						if (this.userDistributionChannelsCode.length > 0) {
							this.userDistributionChannelsCode.forEach(dc => {
								const indexDC = lstAllDistributionChannels.findIndex(i => i.code === dc);
								if (indexDC != -1) {
									lstAssignDistributionChannels.push(lstAllDistributionChannels[indexDC]);
								}
							});
							this.distributionChannelsModel.distributionChannelsDataSource = lstAssignDistributionChannels;
							if (lstAssignDistributionChannels.length === 1) {
								this.distributionChannelsModel.arrSelectedDistributionChannelsList = [lstAssignDistributionChannels[0].code];
							} else {
								this.getUserSelectedDistributionChannelsDataList();
							}
						}
					}
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		})
	}

	private doLoadAssignDistributionChannelsDataList() {
		// For Price List
		let lstAllDistributionChannels = [];
		let lstAssignDistributionChannels = [];

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.DISTRIBUTION_CHANNELS);
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
			next: (response) => {
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						lstAllDistributionChannels = response.data;
						if (this.userDistributionChannelsCode.length > 0) {
							this.userDistributionChannelsCode.forEach(dc => {
								const indexDC = lstAllDistributionChannels.findIndex(i => i.code === dc);
								if (indexDC != -1) {
									lstAssignDistributionChannels.push(lstAllDistributionChannels[indexDC]);
								}
							});
							this.distributionChannelsModel.distributionChannelsDataSource = lstAssignDistributionChannels;
						}
					}
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
				}
			}, error: (error) => {
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
			}
		})
	}

	getUserSelectedDistributionChannelsDataList() {
		let distributionChannelsLst: any = [];
		try {
			distributionChannelsLst = JSON.parse(JSON.stringify(this._AppCommonSrvc.getSectionFromUserSettings(this.parentLevelKey)));
		} catch (e) {
			distributionChannelsLst = [];
		}
		//if (distributionChannelsLst && distributionChannelsLst.length > 0) {
		this.distributionChannelsModel.arrSelectedDistributionChannelsList = distributionChannelsLst;
		//}
	}

	loadDistributionChannelsDataList() {
		const loadParams = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.DISTRIBUTION_CHANNELS,
		};
		this.distributionChannelsModel.distributionChannelsDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams,
			loadMode: "processed",
			key: "code",
		});
	}

	public doOnSelectionChanged(e) {
		//if (this.distributionChannelsModel.arrSelectedDistributionChannelsList.length > 0) {
		this.doSaveDistributionChannelsUserSetting();
		//}
	}

	public doDistributionChannelSelectionChanged(e: any) {
		// console.log('doDistributionChannelSelectionChanged', e);

	}
	public doDistributionChannelValueChanged(e: any) {
		if (this.distributionChannelsModel.selectionMode === 'single') {
			if (e.value && e.value.length >= 2 && e.previousValue && e.previousValue.length > 0) {
				const previousEleIndex = this.distributionChannelsModel.arrSelectedDistributionChannelsList.findIndex(dc => dc === e.previousValue[0]);
				setTimeout(() => {
					this.distributionChannelsModel.arrSelectedDistributionChannelsList.splice(previousEleIndex, 1);
				}, 1);
			}
		}
	}

	public doSaveDistributionChannelsUserSetting() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.SAVE_USER_SETTINGS);
		formData.append('section', this.parentLevelKey);
		formData.append('user_settings', JSON.stringify(this.distributionChannelsModel.arrSelectedDistributionChannelsList));
		if (this.updateUserSettingSbsn) {
			this.updateUserSettingSbsn.unsubscribe();
		}
		this.updateUserSettingSbsn = this._RestApiService.doSubUserSetUpReqFormData(formData).subscribe({
			next: response => {
				if (response && response.flag) {
					this.redirectToDashboard();
					const userSettings = this._AppCommonSrvc.getUserSettings();
					if (userSettings && userSettings.length <= 0) {
						const newSection = {};
						newSection[this.parentLevelKey] = this.distributionChannelsModel.arrSelectedDistributionChannelsList;
						this._AppCommonSrvc.setUserSettings([newSection]);
						this._AppCommonSrvc.setSectionFromUserSettings(this.parentLevelKey, this.distributionChannelsModel.arrSelectedDistributionChannelsList);
					} else {
						this._AppCommonSrvc.setSectionFromUserSettings(this.parentLevelKey, this.distributionChannelsModel.arrSelectedDistributionChannelsList);
					}
				}
			}, error: (error) => {
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public redirectToDashboard() {
		if (this.router.url) {
			let strurlArr = this.router.url.toString().split("/");
			if (strurlArr && strurlArr.length > 0) {
				if (strurlArr[1] === 'dashboard') {
					this._GlobalStateService.notifyDataChangedDuplicate('CALLED_ON_DISTRIBUTION_CHANNEL_CHANGE', { isDefault: true });
				} else {
					this.router.navigate(['/dashboard']);
				}
			}
		}
	}

	public deepCopy(source: any) {
		var copy: UserManagementUI = new UserManagementUI();
		if (null == source || "object" != typeof source) return source;
		// Handle Object
		if (source instanceof Object) {
			for (var attr in source) {
				copy[attr] = source[attr];
			}
			return copy;
		}
	}

	public doDisplayExprDistributionChannelsTagText(event: any) {
		if (!event) {
			return "";
		}
		const name = event.code + ' - ' + event.description;
		return name.toString();
	}

	public onOpened(e) {
		e.component.content().classList.add("setTwoButtonIx");
	}
}
