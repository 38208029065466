<div class="rightBar">
	<div class="rightBlock">
		<div class="contentRow cf">
			<div class="borderBox mrgB0 cf pad0">

				<dx-drawer [openedStateMode]="'shrink'" [position]="'right'" [revealMode]="'slide'" template="template"
					[(opened)]="isOpenPriceListHistoryDrawer" [closeOnOutsideClick]="false"
					[elementAttr]="{ class: 'priceListDetailsDrawerPadIx' }">

					<div class="productDtl fixheightix">
						<div class="row" *ngIf="priceDetails">
							<div class="cols12">
								<div *ngIf="isShowPricelistLifecycle && priceDetails.is_approval_workflow"
									class="rebateStatusTab">
									<div [ngSwitch]="currentPriceListHistoryObj && currentPriceListHistoryObj.status">
										<ul *ngSwitchCase="PricelistStatusValEnum.DRAFT">
											<li
												[ngClass]="{currentStat:currentPriceListHistoryObj.status===PricelistStatusValEnum.DRAFT}">
												<span>{{PricelistStatusLblEnum.DRAFT}}</span>
											</li>
											<li class="disabledStat">
												<span>{{PricelistStatusLblEnum.PENDING_APPROVAL}}</span>
											</li>
											<li class="disabledStat">
												<span>{{PricelistStatusLblEnum.APPROVED}}</span>
											</li>
										</ul>
										<ul *ngSwitchCase="PricelistStatusValEnum.PENDING_APPROVAL">
											<li>
												<span>{{PricelistStatusLblEnum.DRAFT}}</span>
											</li>
											<li
												[ngClass]="{currentStat:currentPriceListHistoryObj.status===PricelistStatusValEnum.PENDING_APPROVAL}">
												<span>{{PricelistStatusLblEnum.PENDING_APPROVAL}}</span>
											</li>
											<li class="disabledStat">
												<span>{{PricelistStatusLblEnum.APPROVED}}</span>
											</li>
										</ul>
										<ul *ngSwitchCase="PricelistStatusValEnum.APPROVED">
											<li>
												<span>{{PricelistStatusLblEnum.DRAFT}}</span>
											</li>
											<li>
												<span>{{PricelistStatusLblEnum.PENDING_APPROVAL}}</span>
											</li>
											<li [ngClass]="{currentStat:currentPriceListHistoryObj.status===PricelistStatusValEnum.APPROVED}"
												class="">
												<span>{{PricelistStatusLblEnum.APPROVED}}</span>
											</li>
										</ul>
										<ul *ngSwitchDefault>
											<li class="disabledStat">
												<span>{{PricelistStatusLblEnum.DRAFT}}</span>
											</li>
											<li class="disabledStat">
												<span>{{PricelistStatusLblEnum.PENDING_APPROVAL}}</span>
											</li>
											<li class="disabledStat">
												<span>{{PricelistStatusLblEnum.APPROVED}}</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div class="cols6">
								<!--<h2 *ngIf="!isQBErpUser" class="mrgB0">
									<span class="mrgR10">Price List No: </span>
									<span class="custIdDtl">
										{{priceDetails.pricelistno ? '#': ''}}{{priceDetails.pricelistno}}
									</span>
								</h2>-->
								<h4 *ngIf="!isQBErpUser" class="textandlableix mrgB5">
									<span class="textlabelix">Price List No.:</span>
									<span class="highlight">{{priceDetails.pricelistno}}</span>
								</h4>

								<h4 class="textandlableix mrgB5"><span class="textlabelix">Price List Name:</span>
									{{priceDetails.pricelistname}}</h4>

							</div>

							<div *ngIf="previousPageUrl === 'PRODUCTS'" class="cols6">
								<div class="alignRight">
									<dx-button class="secondaryBtn mrgR20" text="Export Price List Terms" type="normal"
										stylingMode="contained" (onClick)="doOpenExportPriceListTerms()" icon="exportpdf"
										[useSubmitBehavior]="false" [visible]="isShowTermsAndConditions">
									</dx-button>
									<dx-button class="secondaryBtn" text="Price List History" type="normal"
										stylingMode="contained" (onClick)="doOpenPriceListDrawer()" [useSubmitBehavior]="false"
										[disabled]="!isShowPriceListHistoryBtn"
										[ngClass]="{'greyButton':!isShowPriceListHistoryBtn}">
									</dx-button>
								</div>
							</div>

							<div class="cols12 mrgT3">
								<div *ngIf="currentPriceListHistoryObj" class="width100p alignLeft mrgB10">
									<div *ngIf="(currentPriceListHistoryObj.startdate || currentPriceListHistoryObj.enddate)"
										class="width100p alignLeft">
										<span class="custIdDtl vtSeparator" *ngIf="currentPriceListHistoryObj.startdate">
											Start Date:
											<span>{{currentPriceListHistoryObj.startdate || ''}}</span>
										</span>
										<span *ngIf="currentPriceListHistoryObj.startdate && currentPriceListHistoryObj.enddate"
											class="vtSeparator">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
										<span class="custIdDtl vtSeparator" *ngIf="currentPriceListHistoryObj.enddate">
											End Date:
											<span>{{currentPriceListHistoryObj.enddate || ''}}</span>
										</span>
										<span class="vtSeparator">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
										<span class="custIdDtl vtSeparator">
											Price List Type:
											<span>{{priceDetails.pricelist_type || PriceListTypesValEnum.STANDARD |
												filterFromList:listOfPricelistTypes : 'value' : 'text'}}</span>
										</span>

										<span
											*ngIf="priceDetails.is_approval_workflow && currentPriceListHistoryObj.approvers && currentPriceListHistoryObj.approvers.length>0"
											class="vtSeparator">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
										<span class="custIdDtl vtSeparator"
											*ngIf="priceDetails.is_approval_workflow && currentPriceListHistoryObj.approvers && currentPriceListHistoryObj.approvers.length > 0">
											Approvers:
											<span>{{currentPriceListHistoryObj.approvers[0] || '' |
												filterFromList:listOfApproverUsers :
												'sub_account_id' : 'name'}}</span>
										</span>
										<span *ngIf="priceDetails.distribution_channels && isEnableDistributionChannels"
											class="vtSeparator">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
										<span class="custIdDtl vtSeparator"
											*ngIf="priceDetails.distribution_channels && isEnableDistributionChannels">
											Distribution Channel:
											<span>{{priceDetails.distribution_channels || ''
												|filterFromList:listOfDistributionChannels : 'code' : 'code'}} -
												{{priceDetails.distribution_channels || '' |
												filterFromList:listOfDistributionChannels : 'code' : 'description'}}</span>
										</span>
									</div>
									<!-- <div *ngIf="isShowStatus" class="width100p alignRight">
										<span class="custIdDtl statusitems">
											Status :
											<span class="statusitemsbg">
												{{priceDetails.status || 'draft' |
												filterFromList:listOfPricelistStatuses : 'value' :
												'text'}}
											</span>
										</span>
									</div> -->
								</div>
							</div>

						</div>
						<!-- <div class="width100p alignLeft mrgB10">
							<div *ngIf="isShowStatus" class="width50p alignRight">
								<span class="custIdDtl statusitems">
									Status :
									<span class="statusitemsbg">
										{{priceDetails.status || 'draft' |
										filterFromList:listOfPricelistStatuses : 'value' :
										'text'}}
									</span>
								</span>
							</div>
						</div> -->

					</div>
					<div class="contentRow cf">
						<div class="tabbing">
							<ul>
								<li (click)="clickOnTab('listOfProducts')"
									[ngClass]="{active: currentTab === 'listOfProducts'}">
									<a>Items</a>
								</li>
								<li (click)="clickOnTab('listOfCustomers')"
									[ngClass]="{active: currentTab === 'listOfCustomers'}">
									<a>Customers</a>
								</li>
								<li (click)="clickOnTab('END_USERS_LISTING')"
									[ngClass]="{active: currentTab === 'END_USERS_LISTING'}">
									<a>End-Users</a>
								</li>
								<li *ngIf="isShowTermsAndConditions" (click)="clickOnTab('terms_conditions')"
									[ngClass]="{active: currentTab === 'terms_conditions'}">
									<a>Terms & Conditions</a>
								</li>
								<li *ngIf="isEnablePricelistApprovalWorkflow && priceDetails && priceDetails.is_approval_workflow"
									(click)="clickOnTab('approval_trails')"
									[ngClass]="{active: currentTab === 'approval_trails'}">
									<a>Approval Trails</a>
								</li>
							</ul>
						</div>
						<div class="" *ngIf="currentTab === 'listOfProducts'">

							<div *ngIf="previousPageUrl === 'PRODUCTS'" class="inlineRadio mrgB10">
								<div class="notificationRadio">
									<span class="mainLabel">Default UOM View</span>
									<dx-switch [(value)]="isDefaultItemView" (onValueChanged)="doSwitchChanged($event)">
									</dx-switch>
								</div>
							</div>
							<!-- <div class="row">
									<div class="cols3">
										<div class="dx-field">
											<div class="dx-field-label">Default Item View</div>
											<div class="dx-field-value">
												<dx-switch (onValueChanged)="doSwitchChanged($event)" [(value)]="isDefaultItemView">
												</dx-switch>
											</div>
										</div>
									</div>
								</div> -->

							<div *ngIf="!isDefaultItemView">
								<dx-data-grid #dataGridContainer id="gridContainerHeightIx"
									[elementAttr]="{ class: 'notDefaultItemView' }" [dataSource]="dxProductDataSource"
									[showBorders]="false" [remoteOperations]="{ groupPaging: true }"
									[dateSerializationFormat]="'yyyy-MM-dd'" [showBorders]="true" [showColumnLines]="true"
									[showRowLines]="false" [allowColumnResizing]="true" [columnResizingMode]="'nextColumn'"
									[width]="'100%'" (onToolbarPreparing)="doToolbarPreparing($event)" [wordWrapEnabled]="true"
									class="" [height]="'calc(100vh - 470px)'">
									<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"
										[placeholder]="'Search Item...'" [width]="300"></dxo-search-panel>
									<dxo-sorting mode="multiple"></dxo-sorting>
									<!-- <dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
										<dxo-pager [visible]="true" [showPageSizeSelector]="true"
											[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
										</dxo-pager> -->
									<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
									<dxo-group-panel [visible]="false"></dxo-group-panel>
									<dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
									<dxo-export [enabled]="false"></dxo-export>
									<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [scrollByThumb]="true"
										[showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>

									<div *dxTemplate="let data of 'templateActionBtns'">
										<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false"
											[showArrowIcon]="false" text="Action"
											class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
											[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name"
											keyExpr="btn_code" icon="spindown" (onButtonClick)="doActionsBtnClick($event)"
											(onItemClick)="doActionsBtnItemClick($event)"
											[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
									</div>

									<dxi-column dataField="itemsku" caption="SKU" dataType="string" [visible]="false"
										*ngIf="isQBOnlineErpUser">
									</dxi-column>

									<dxi-column dataField="itemname" caption="Item" dataType="string" [visible]="false">
									</dxi-column>

									<dxi-column dataField="description" caption="Description" dataType="string"
										[visible]="false">
									</dxi-column>

									<dxi-column dataField="min" caption="Minimum" [visible]="isShowMinMaxColumn"
										dataType="number" cellTemplate="cellTemplateMinimum">
										<div *dxTemplate="let element of 'cellTemplateMinimum'">
											{{element.data.min || 0}}
										</div>
									</dxi-column>

									<dxi-column dataField="max" caption="Maximum" [visible]="isShowMinMaxColumn"
										dataType="number" cellTemplate="cellTemplateMaximum">
										<div *dxTemplate="let element of 'cellTemplateMaximum'">
											{{element.data.max || 0}}
										</div>
									</dxi-column>

									<dxi-column dataField="uom" caption="UOM" dataType="string" cellTemplate="cellTemplateUOM">
										<div *dxTemplate="let element of 'cellTemplateUOM'">
											{{element.data.uom}}
										</div>
									</dxi-column>

									<dxi-column dataField="price" caption="Price" dataType="number" dataType="number">
										<dxo-format type="currency" [precision]="decimalPointForCurrency">
										</dxo-format>
										<div *dxTemplate="let element of 'cellTemplatePrice'">
											{{element.data.price || 0 | customCurrencyPipe }}
										</div>
									</dxi-column>

									<dxi-column dataField="baseuom" caption="Default UOM" dataType="string"
										cellTemplate="cellTemplatebaseUOM" alignment="center">
										<div *dxTemplate="let element of 'cellTemplatebaseUOM'">
											<span *ngIf="element.data.uom && element.data.baseuom">
												{{element.data.baseuom.toString().toLowerCase() ==
												element.data.uom.toString().toLowerCase()
												?
												'Default':
												''}}
											</span>
										</div>
									</dxi-column>

									<dxi-column dataField="item_full_name" [groupIndex]="0" [autoExpandGroup]="false"
										caption="Item" dataType="string">
									</dxi-column>

								</dx-data-grid>
							</div>

							<div *ngIf="isDefaultItemView">
								<div *ngIf="isShowDataGrid" class="tableOut">
									<dx-data-grid #dataGridContainer id="gridContainerHeightIx"
										[dataSource]="dxItemsWithDefaultUomDataSource" [remoteOperations]="true"
										[showBorders]="true" [showColumnLines]="true" [showRowLines]="false"
										[allowColumnResizing]="true" [columnResizingMode]="'nextColumn'" [width]="'100%'"
										(onToolbarPreparing)="doToolbarPreparing($event)" class="hideFilterPanelInx">
										<dxo-filter-row [visible]="true"></dxo-filter-row>
										<dxo-filter-panel [visible]="true"></dxo-filter-panel>
										<dxo-header-filter [visible]="true" [showRelevantValues]="true"></dxo-header-filter>
										<dxo-sorting mode="multiple"></dxo-sorting>
										<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
										<dxo-pager [visible]="true" [showPageSizeSelector]="true"
											[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
										</dxo-pager>
										<dxo-export [enabled]="false"></dxo-export>
										<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
											[useNative]="false"></dxo-scrolling>
										<div *dxTemplate="let data of 'templateFilterText'">
											<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
												<span>{{filterText}}</span>
											</div>
										</div>

										<div *dxTemplate="let data of 'templateActionBtns'">
											<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false"
												[showArrowIcon]="false" text="Action"
												class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx"
												[items]="listOfActions" [wrapItemText]="true" [stylingMode]="'outlined'"
												displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
												(onButtonClick)="doActionsBtnClick($event)"
												(onItemClick)="doActionsBtnItemClick($event)"
												[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
										</div>

										<dxi-column dataField="itemsku" caption="SKU" dataType="string" [visible]="true"
											[allowHeaderFiltering]="false" *ngIf="isQBOnlineErpUser">
										</dxi-column>

										<dxi-column dataField="itemname" caption="Item" dataType="string" sortOrder="asc"
											[visible]="true" [allowHeaderFiltering]="false">
										</dxi-column>

										<dxi-column dataField="description" caption="Description" dataType="string"
											[visible]="true" [allowHeaderFiltering]="false">
										</dxi-column>

										<dxi-column dataField="min" caption="Minimum" [visible]="isShowMinMaxColumn"
											dataType="number" [allowHeaderFiltering]="false" cellTemplate="cellTemplateMinimum">
											<div *dxTemplate="let element of 'cellTemplateMinimum'">
												{{element.data.min || 0}}
											</div>
										</dxi-column>

										<dxi-column dataField="max" caption="Maximum" [visible]="isShowMinMaxColumn"
											dataType="number" [allowHeaderFiltering]="false" cellTemplate="cellTemplateMaximum">
											<div *dxTemplate="let element of 'cellTemplateMaximum'">
												{{element.data.max || 0}}
											</div>
										</dxi-column>

										<dxi-column dataField="baseuom" caption="Default UOM" dataType="string"
											[allowHeaderFiltering]="false" cellTemplate="cellTemplatebaseUOM" alignment="center">
											<div *dxTemplate="let element of 'cellTemplatebaseUOM'">
												{{element.data.baseuom || ''}}
											</div>
										</dxi-column>

										<dxi-column dataField="price" caption="Price" [allowHeaderFiltering]="false"
											dataType="number">
											<dxo-format type="currency" [precision]="decimalPointForCurrency">
											</dxo-format>
											<div *dxTemplate="let element of 'cellTemplatePrice'">
												{{element.data.price || 0 | customCurrencyPipe }}
											</div>
										</dxi-column>
									</dx-data-grid>
								</div>
							</div>
						</div>

						<div class="" *ngIf="currentTab === 'listOfCustomers'">
							<dx-data-grid #dataGridContainer id="gridContainerHeightIx" [dataSource]="dxCustomerDataSource"
								[showBorders]="true" [remoteOperations]="true" [filterValue]="filterValue"
								[dateSerializationFormat]="'yyyy-MM-dd'" [showColumnLines]="true" [showRowLines]="false"
								[allowColumnResizing]="true" [columnResizingMode]="'nextColumn'" [width]="'100%'"
								[filterSyncEnabled]="true" class="" [height]="'calc(100vh - 430px)'"
								(onToolbarPreparing)="doToolbarPreparingCustomerTab($event)">

								<dxo-filter-row [visible]="true"></dxo-filter-row>
								<dxo-header-filter [visible]="true" [showRelevantValues]="true"></dxo-header-filter>
								<dxo-sorting mode="multiple"></dxo-sorting>
								<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
								<dxo-pager [visible]="true" [showPageSizeSelector]="true"
									[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
								</dxo-pager>
								<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'"
									[useNative]="false"></dxo-scrolling>
								<dxo-column-chooser [enabled]="true"></dxo-column-chooser>

								<div *dxTemplate="let data of 'templateActionBtns'">
									<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false"
										[showArrowIcon]="false" text="Action"
										class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx"
										[items]="listOfActionsCustomerTab" [wrapItemText]="true" [stylingMode]="'outlined'"
										displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
										(onButtonClick)="doActionsBtnClickCustomerTab($event)"
										(onItemClick)="doActionsBtnItemClickCustomerTab($event)"
										[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
								</div>

								<dxi-column dataField="pricelistnum" caption="pricelistnum" dataType="string" [visible]="false"
									[showInColumnChooser]="false">
								</dxi-column>

								<dxi-column dataField="code" caption="Customer Code" dataType="string"
									[allowHeaderFiltering]="false">
								</dxi-column>

								<dxi-column dataField="name" caption="Customer Name" dataType="string"
									[allowHeaderFiltering]="false">
								</dxi-column>

							</dx-data-grid>
						</div>

						<app-pricelist-end-users-listing *ngIf="currentTab === 'END_USERS_LISTING'"
							[priceListDetailsIP]="currentPriceListHistoryObj"
							[refreshEndUsersListIP]="doRefreshEndUsersListIP"></app-pricelist-end-users-listing>

						<app-pricelist-approval-trails *ngIf="currentTab === 'approval_trails'"
							[priceListDetailsIP]="currentPriceListHistoryObj"
							[refreshApprovalTrailIP]="doRefreshApprovalTrailIP"
							[listOfApproverUsersIP]="listOfApproverUsers"></app-pricelist-approval-trails>

						<app-pricelist-terms-conditions *ngIf="currentTab === 'terms_conditions'"
							[priceListDetailsIP]="priceDetails" [currentPriceListDetailsIP]="currentPriceListHistoryObj"
							(refreshTermsConditionsIP)="doRefreshTermsConditions($event)"></app-pricelist-terms-conditions>

						<div class="row">
							<div class="cols12 mrgT20 padR0">
								<div
									*ngIf="isEnablePricelistApprovalWorkflow && currentPriceListHistoryObj && priceDetails && priceDetails.is_approval_workflow && isShowDropDownButton"
									class="alignRight">
									<dx-drop-down-button [dropDownOptions]="{ width: 250 }" [splitButton]="true"
										[showArrowIcon]="isShowArrowIconDrpDwnBtn" [useSelectMode]="true"
										[selectedItemKey]="selectedKeyDropDownBtn" [text]="getSubmitBtnText()"
										[items]="listOfPricelistStatusesBtns" [wrapItemText]="true" displayExpr="label"
										keyExpr="value" (onButtonClick)="doSaveUpdatePriceList($event)"
										(onItemClick)="doSaveUpdatePricelistStatusChanged($event)"
										class="button dxSubmitBtn submitDDBtn" [width]="250">
									</dx-drop-down-button>
									<!-- doSubmitForApproval -->
								</div>
							</div>
						</div>
					</div>
					<div *dxTemplate="let data of 'template'" style="width: 300px">

						<div class="priceListHistoryix">
							<h2>Price List History</h2>
							<dx-button icon="close" class="alignRight priceListHistorycloseix" type="normal"
								stylingMode="outlined" (click)="doClosePriceListDrawer()">
							</dx-button>


							<dx-list #listOfPriceListHistoryRef [dataSource]="listOfPriceListHistory" keyExpr="unique_id"
								selectionMode="single" [focusStateEnabled]="false" [hoverStateEnabled]="false"
								selectAllMode="allPages" [showSelectionControls]="false"
								[(selectedItemKeys)]="currentPriceListFromHistory"
								(onSelectionChanged)="doSelectionChangedPriceListHistory($event)" itemTemplate="item"
								class="priceListHistoryallix" height="765">

								<div *dxTemplate="let element of 'item'">
									<div class="priceListHistoryinnertopix">
										<div class="priceListHistoryinnerix">
											<div class="width100p">
												<div *ngIf="isShowPricelistLifecycle && priceDetails.is_approval_workflow"
													class="d-flex">
													<h3 class="mrgB0 font-weight-bold flex-grow-1"
														[ngClass]="{'defaultClr':element.status === PricelistStatusValEnum.DRAFT,'orange': element.status === PricelistStatusValEnum.PENDING_APPROVAL,'green': element.status === PricelistStatusValEnum.APPROVED}">
														{{element.status || '' | filterFromList:listOfPricelistStatuses : 'value' :
														'text'}}
													</h3>
													<!-- <span *ngIf="!element.enddate" class="greenTag alignRight line-height-unset">
														Current
													</span> -->
												</div>
												<h3 class="mrgB0 width100p">No. of Item(s)
													<span>{{element.items ? element.items.length : 0}}</span>
												</h3>
												<h3 *ngIf="isShowPricelistLifecycle && priceDetails.is_approval_workflow && element.approvers && element.approvers.length > 0"
													class="mrgB0 width100p">
													Approver:
													<span class="black font-weight-normal">{{element.approvers[0] || '' |
														filterFromList:listOfApproverUsers :
														'sub_account_id' : 'name' }}</span>
												</h3>
											</div>
											<div class="row">
												<div class="cols6">
													<h4 class="mrgB0">Start Date</h4>
													<h5 class="mrgB0">
														{{element.startdate || '-'}}
													</h5>
												</div>
												<div class="cols6">
													<h4 class="mrgB0">End Date</h4>
													<h5 class="mrgB0">
														{{element.enddate || '-'}}
													</h5>
												</div>
											</div>
										</div>
									</div>
								</div>
							</dx-list>
						</div>

					</div>
				</dx-drawer>
			</div>
		</div>
	</div>
</div>

<app-export-pricelist-terms-conditions-dialog *ngIf="isShowExportPriceListTermsPopup"
	[exportPricelistTermsProps]="exportPricelistTermsProps"></app-export-pricelist-terms-conditions-dialog>